import React, { useState, useEffect } from "react";

const Approval1Table = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveTypeFilter, setLeaveTypeFilter] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    itemId: null,
    newStatus: "",
    description: "",
  });
  const [rejectDialog, setRejectDialog] = useState({
    isOpen: false,
    itemId: null,
    description: "",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/leave/getleaveapprove"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLeaveTypeChange = (e) => {
    setLeaveTypeFilter(e.target.value);
  };

  const handleApprovalChange = (item, newStatus) => {
    setConfirmationDialog({
      isOpen: true,
      itemId: item.id,
      newStatus,
      description: "",
    });
  };

  const handleReject = (item) => {
    setRejectDialog({
      isOpen: true,
      itemId: item.id,
      description: "",
    });
  };

  const confirmApprovalChange = async () => {
    const { itemId, newStatus, description } = confirmationDialog;
    const selectedItem = data.find((item) => item.id === itemId);
    const { employee_no, requested_date } = selectedItem;

    const requestBody = {
      id: itemId,
      employee_no: employee_no,
      leave_type: selectedItem.leave_type,
      requested_date: requested_date,
      approved_status_1: newStatus,
      approved_description: description,
    };

    console.log("Sending data:", requestBody);

    try {
      const response = await fetch(
        `https://back-demo-british.talentfort.live/v1/hris/leave/approve`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const updatedItem = await response.json();
      setData((prevData) =>
        prevData.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );
      // Trigger a GET request to refresh data
      fetchData();
      setConfirmationDialog({
        isOpen: false,
        itemId: null,
        newStatus: "",
        description: "",
      });
    } catch (err) {
      console.error("Failed to update approval status:", err);
    }
  };

  const confirmRejectChange = async () => {
    const { itemId, description } = rejectDialog;
    const selectedItem = data.find((item) => item.id === itemId);
    const { employee_no, requested_date } = selectedItem;

    const requestBody = {
      id: itemId,
      employee_no: employee_no,
      leave_type: selectedItem.leave_type,
      requested_date: requested_date,
      approved_status_1: "REJECT",
      approved_description: description,
    };

    console.log("Sending data:", requestBody);

    try {
      const response = await fetch(
        `https://back-demo-british.talentfort.live/v1/hris/leave/approve`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const updatedItem = await response.json();
      setData((prevData) =>
        prevData.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );
      // Trigger a GET request to refresh data
      fetchData();
      setRejectDialog({
        isOpen: false,
        itemId: null,
        description: "",
      });
    } catch (err) {
      console.error("Failed to update approval status:", err);
    }
  };

  const cancelApprovalChange = () => {
    setConfirmationDialog({
      isOpen: false,
      itemId: null,
      newStatus: "",
      description: "",
    });
  };

  const cancelRejectChange = () => {
    setRejectDialog({
      isOpen: false,
      itemId: null,
      description: "",
    });
  };

  const filteredData = data.filter((item) => {
    const employeeNo = item.employee_no || "";
    const leaveType = item.leave_type || "";
    const matchesSearchQuery = employeeNo
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesLeaveTypeFilter = leaveTypeFilter
      ? leaveType === leaveTypeFilter
      : true;
    return matchesSearchQuery && matchesLeaveTypeFilter;
  });

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Approve One Leave Details Table
      </p>

      <div className="mt-5 ml-6 mb-[50px]"></div>

      <div className="flex justify-between items-center mt-[-50px]">
        <div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Employee Code"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div>
              <button
                className="text-headerColor font-bold"
                onClick={() => {
                  setSearchQuery("");
                  setLeaveTypeFilter("");
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <select
                className="w-[480px] h-[55px] rounded-[22px] bg-white"
                value={leaveTypeFilter}
                onChange={handleLeaveTypeChange}
              >
                <option value="" disabled hidden>
                  Sort by Leave Type
                </option>
                <option value="">All</option>
                {Array.from(new Set(data.map((item) => item.leave_type))).map(
                  (leaveType) => (
                    <option key={leaveType} value={leaveType}>
                      {leaveType}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%]">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Employee NO</th>
              <th className="text-primary px-4 py-2">Leave Type</th>
              <th className="text-primary px-4 py-2">Request Date</th>
              <th className="text-primary px-4 py-2">Reason</th>

              <th className="text-primary px-4 py-2">Approval</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="bg-white px-4 py-2 text-center">
                  Loading...
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="6" className="bg-white px-4 py-2 text-center">
                  Error: {error.message}
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td colSpan="6" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            ) : (
              filteredData.map((item) => (
                <tr key={item.id}>
                  <td className="bg-white px-4 py-2">{item.employee_no}</td>
                  <td className="bg-white px-4 py-2">{item.leave_type}</td>

                  <td className="bg-white px-4 py-2">{item.requested_date}</td>
                  <td className="bg-white px-4 py-2">{item.reason}</td>

                  <td className="bg-white px-4 py-2 flex justify-center">
                    <div className="flex gap-2">
                      <button
                        className="bg-[#6A8293] text-white px-4 py-2 rounded"
                        onClick={() => handleApprovalChange(item, "APPROVE")}
                      >
                        Approve
                      </button>
                      <button
                        className="bg-[#CED6DC] text-white px-4 py-2 rounded"
                        onClick={() => handleReject(item)}
                      >
                        Reject
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {confirmationDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">
              Are you sure you want to approve this leave request?
            </p>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Enter approval description"
              value={confirmationDialog.description}
              onChange={(e) =>
                setConfirmationDialog((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
            />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={cancelApprovalChange}
              >
                No
              </button>
              <button
                className="bg-[#6A8293] text-white px-4 py-2 rounded"
                onClick={confirmApprovalChange}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {rejectDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">
              Are you sure you want to reject this leave request?
            </p>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Enter rejection description"
              value={rejectDialog.description}
              onChange={(e) =>
                setRejectDialog((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
            />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={cancelRejectChange}
              >
                No
              </button>
              <button
                className="bg-[#6A8293] text-white px-4 py-2 rounded"
                onClick={confirmRejectChange}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Approval1Table;
