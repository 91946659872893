import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosCloseCircle } from "react-icons/io";

function AssignDatePopup({ onClose }) {
  const [selectedDates, setSelectedDates] = useState([]);

  // Helper function to check if the date is valid
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const handleDateChange = (date) => {
    console.log("Received date:", date);
    if (isValidDate(date)) {
      // Check if the date is already selected
      if (
        selectedDates.some(
          (selectedDate) => selectedDate.getTime() === date.getTime()
        )
      ) {
        // Remove the date if already selected
        setSelectedDates(
          selectedDates.filter(
            (selectedDate) => selectedDate.getTime() !== date.getTime()
          )
        );
      } else {
        // Add the new date if not already selected
        setSelectedDates([...selectedDates, date]);
      }
    }
  };

  // Remove a selected date
  const handleRemoveDate = (dateToRemove) => {
    setSelectedDates(
      selectedDates.filter((date) => date.getTime() !== dateToRemove.getTime())
    );
  };

  // Render selected dates and provide an option to remove each
  const renderSelectedDates = () => {
    return selectedDates
      .sort((a, b) => a - b)
      .map((date, index) => (
        <div key={index} className="flex items-center mt-1">
          {date.toDateString()}

          <div>
            <IoIosCloseCircle
              onClick={() => handleRemoveDate(date)}
              className="w-[15px] h-[15px] cursor-pointer ml-1"
            />
          </div>
        </div>
      ));
  };

  const onSave = async (selectedDates) => {
    const payload = {
      leave_dates: selectedDates.map((date) => ({
        leave_date: date.toISOString().split("T")[0], // Formats date as "YYYY-MM-DD"
      })),
    };
    console.log("Payload to send:", payload);

    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/leave/selectleavedate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      console.log("Server Response:", responseData);

      if (response.ok) {
        alert("Dates successfully saved!");
        onClose(); // Optionally close the modal on success
      } else {
        alert(`Failed to save dates: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Failed to send dates:", error);
      alert("An error occurred while saving dates!");
    }
  };

  return (
    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[600px]">
        <div className="flex justify-between items-center">
          <p className="text-headerColor text-[28px] font-semibold font-sans">
            Assign Date Restrictions
          </p>

          <IoIosCloseCircle
            onClick={onClose}
            className="w-[29px] h-[29px] cursor-pointer"
          />
        </div>
        <div className="mt-4 flex">
          <div className="flex-1">
            <DatePicker
              onChange={handleDateChange}
              highlightDates={selectedDates}
              inline
            />
          </div>

          <div className="flex-1 ml-4 overflow-y-auto h-[220px] ">
            <h3 className="text-lg font-semibold ml-[25%]">Selected Dates</h3>
            <ul>{renderSelectedDates()}</ul>
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={() => onSave(selectedDates)}
            className="bg-[#252C58] text-white px-4 py-2 rounded-lg"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AssignDatePopup;
