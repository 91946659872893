import React, { useState, useEffect } from "react";
import Calendar from "../../../components/calender/calender.jsx";

function ViewDate({ onClose }) {
  const [leaveDates, setLeaveDates] = useState([]);

  useEffect(() => {
    // Fetch leave dates when the component mounts
    const fetchLeaveDates = async () => {
      try {
        const response = await fetch(
          "https://back-demo-british.talentfort.live/v1/hris/leave/getleavedates"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        const data = await response.json();
        setLeaveDates(data); // Store the leave dates
      } catch (error) {
        console.error("Error fetching leave dates:", error);
        alert("Failed to fetch leave dates.");
      }
    };

    fetchLeaveDates();
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts

  return (
    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <div className="bg-white p-6 rounded-lg shadow-lg ">
        <p>View the restricted dates for leaves.</p>
        <Calendar
          leaveDates={leaveDates.map((date) => new Date(date.leave_date))}
        />
        <button onClick={onClose} className="p-2 bg-[#252C58] text-white rounded">
          Close
        </button>
      </div>
    </div>
  );
}

export default ViewDate;
