import React from "react";
import { IoCloseSharp } from "react-icons/io5";

const Leave_Reports = ({ cardName, onClose }) => {
  return (
    <div className="bg-white p-6 rounded-lg w-[40%] h-[50%] relative">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 bg-white text-[#797C80] rounded-full p-1"
      >
        <div className="bg-[#EFA5A5] rounded-full p-2">
          <IoCloseSharp />
        </div>
      </button>
      <p className="font-sans font-bold text-[24px] text-[#797C80] mb-[8%] ">
        {cardName} Reports
      </p>
      {/* Rest of your popup content */}
      <div className="max-h-[250px] overflow-y-auto">
        <table className="w-full border-none scrollbar-green">
          <thead>
            <tr>
              <th className="bg-white text-[12px] font-semibold text-headerColor font-sans">
                EMP ID
              </th>
              <th className="bg-white text-[12px] font-semibold text-headerColor font-sans">
                EMP Name
              </th>
              <th className="bg-white text-[12px] font-semibold text-headerColor font-sans">
                View Document
              </th>

              <th className="bg-white text-[12px] font-semibold text-headerColor font-sans">
                Decision
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 font-bold"></td>
              <td className="text-red-700  font-bold rounded-lg "></td>
              <td className="py-2 px-4 font-bold"></td>
              <td className="py-2 px-4">
                <div className="flex gap-3">
                  <div>
                    <button className="bg-[#DDF2DC] font-semibold p-2 rounded-[10px]">
                      Approve
                    </button>
                  </div>
                  <div>
                    <button className="bg-[#E2CECE] font-semibold p-2 rounded-[10px]">
                      Reject
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leave_Reports;
