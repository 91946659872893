import React from "react";
import { Link } from "react-router-dom";

const allowancesAndDeductions = [
  { name: "Salary Arrears", value: "salary_arrears" },
  { name: "Special  1", value: "special_allowance_1" },
  { name: "Defficile ", value: "defficale_allowance" },
  { name: "Acting ", value: "acting_allowance" },
  { name: "Special  2", value: "special_allowance_2" },
  { name: "Acting  Arrears", value: "acting_allowance_arrers" },
  { name: "Special  3", value: "special_allowance_3" },
  { name: "Special  4", value: "special_allowance_4" },
  { name: "Salary Advance", value: "salary_advance" },
  { name: "Welfare Members", value: "walfare_members" },
  { name: "Festival Advance", value: "festival_advance" },
  { name: "Bank Loan 1", value: "bank_loan_1" },
  { name: "Other Deduction", value: "other_deduction" },
  { name: "Welfare Loan", value: "walfare_loan" },
  { name: "Union 1", value: "union_1" },
  { name: "Union 2", value: "union_2" },
  { name: "Union 3", value: "union_3" },
  { name: "Union 4", value: "union_4" },
  { name: "Union 5", value: "union_5" },
  { name: "Buddhist Association Member", value: "baudda_sangamay_member" },
  { name: "Sanasa Loan", value: "sanasa_loan" },
  { name: "Other Deduction 2", value: "other_deduction_2" },
  { name: "Saving to Bank", value: "saving_to_bank" },
  { name: "Sanasa Member", value: "sanasa_member" },
  { name: "Sanasa Advance", value: "sanasa_advance" },
  { name: "Donation", value: "donation" },
  { name: "Other Deduction 3", value: "other_deduction_3" },
  { name: "Distress Loan", value: "distres_loa" },
  { name: "Distress Loan Interest", value: "distres_loan_interest" },
  { name: "Bank Loan 2", value: "bank_loan_2" },
  { name: "Working Days", value: "working_days" },
];

const payment_menu = () => {
  const handleClick = (value) => {
    if (value) {
      window.location.href = `/payment_details/${value}`;
    }
  };

  return (
    <div className="bg-background h-screen overflow-hidden">
      <div>
        <p className="ml-6 mt-6 font-sans text-[25px] font-bold text-[#797C80]">
          Payments Menu
        </p>
      </div>

      <div className="flex justify-between items-center gap-[45%] mt-[-40px]">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form className="form relative">
              <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  className="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
            </form>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6 mt- ">
            <div
              key=""
              className="bg-white rounded-[32px] w-[350px] h-[180px] p-4 shadow-md flex flex-col justify-between"
            >
              <div>
                <h2 className="text-[20px] font-semibold mb-1 font-sans">
                  Payments
                </h2>
                <h2 className="text-[20px] font-semibold mb-1 font-sans">
                  Details
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-[#183D58] w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]">
                  Click Here
                </button>

                <div className="bg-[#183D58] w-[13px] h-[37px] rounded-xl"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-5 ml-6 font-sans font-semibold text-[#797C80] text-[20px] mb-[30px]">
          <p>Allowances & Deductions</p>
          <div className="flex justify-center ">
            <div className="bg-white w-[75%] rounded-[39px] mt-6 mr-[5%] h-[450px] overflow-y-auto scrollbar-thin scrollbar-thumb-primary mb-5">
              <div className="ml-6 grid grid-cols-4 gap-1">
                {allowancesAndDeductions.map((item) => (
                  <Link
                    to={`/payment_details/${item.value}/${item.name}`}
                    key={item.id}
                  >
                    <button className="bg-[#183D58] p-3 rounded-[20px] w-[180px] mt-8 h-[60px] text-white">
                      <p className="text-[11px]">{item.name} Allowance</p>
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default payment_menu;
