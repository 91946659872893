import React from "react";
import "./App.css";
import SideMenu from "./components/sidebar/side_menu/side_menu";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Dashboard from "./modules/HRIS/dashboard/card.jsx";
import Onboard from "./modules/HRIS/employee/emp_onBoard.jsx";
import Emp_Nav from "./modules/HRIS/employee/emp_nav.jsx";
import View_Export_Table from "./modules/HRIS/employee/view_export/view_export_table.jsx";
import History_Log from "./modules/HRIS/employee/history_log.jsx";
import User_Profile from "./modules/HRIS/employee/user_profile.jsx";
import Attendance from "./modules/HRIS/attendance/Attendance.jsx";
import Inandout_Regular from "./modules/HRIS/attendance/in_out/in_and_out_time_regular.jsx";
import Inandout_Shift from "./modules/HRIS/attendance/in_out/in_and_out_time_shift.jsx";
import Leave from "./modules/HRIS/attendance/leave/leave.jsx";
import Lateness_Permission from "./modules/HRIS/attendance/late_permission/lateness_permission.jsx";
import Leave_Doc from "./modules/HRIS/attendance/late_permission/document_log.jsx";
import OT from "./modules/HRIS/attendance/OT/OT.jsx";
import Payments_Menu from "./modules/HRIS/payrole/payment_menu.jsx";
import Payment_Details from "./modules/HRIS/payrole/payment_details.jsx";
import Training from "./modules/HRIS/performance/trainings/training_cards.jsx";
import Completed_Training from "./modules/HRIS/performance/trainings/completed_training.jsx";
import Upcoming_Trainings from "./modules/HRIS/performance/trainings/upcoming_trainings.jsx";
import Examinations_Card from "./modules/HRIS/performance/examinations/examinations_card.jsx";
import Reports_Analytics from "./modules/HRIS/reports_analytics/reports_analytics.jsx";
import Performance_Nav from "./modules/HRIS/performance/performance_nav.jsx";
import Completed_Examinations from "./modules/HRIS/performance/examinations/completed/completed_examinations.jsx";
import Upcoming_Examinations from "./modules/HRIS/performance/examinations/upcoming/upcoming_examinations.jsx";
import Salary_Increments from "./modules/HRIS/performance/salary/salary_increments.jsx";
import Leave_Management from "./modules/HRIS/leave_management/leave-nav.jsx";
import Leave_Info from "./modules/HRIS/leave_management/leave-info.jsx";
import Loan_Nav from "./modules/HRIS/loan_information/loan_nav.jsx";
import View_Loan_Info from "./modules/HRIS/loan_information/view_loan_info.jsx";
import Review_Guarantors from "./modules/HRIS/loan_information/review_guarantors.jsx";
import Loan_Elibility from "./modules/HRIS/loan_information/loan_eligibility.jsx";
import Login from "../src/components/login/login.jsx";
import Leave_Reports from "./modules/HRIS/reports_analytics/leave_reports_info.jsx";
import Error from "../src/components/404error.jsx";
import Approval1 from "../src/modules/HRIS/approval_process/approval1/approval1.jsx";
import Approval2 from "../src/modules/HRIS/approval_process/approval2/approval2.jsx";
import Approval1_Info from "../src/modules/HRIS/approval_process/approval1/approval1_table.jsx";
import Approval2_Info from "../src/modules/HRIS/approval_process/approval2/approval2_table.jsx";
import Approval1user from "../src/modules/HRIS/approval_process/approval1/approve1_tableuser.jsx";
import Approval2user from "../src/modules/HRIS/approval_process/approval2/appoval_user_details.jsx";

const SidebarChecker = ({ children }) => {
  const location = useLocation();
  const validRoutes = [
    "/dashboard",
    "/emp-onboarding",
    "/emp-nav",
    "/view-export-table",
    "/history-log",
    "/user-profile/:employee_no",
    "/attendance",
    "/in-and-out-regular",
    "/in-and-out-shift",
    "/leaves",
    "/lateness-permission",
    "/leave-doc",
    "/over-time",
    "/payments-menu",
    "/payment_details/:value/:name",
    "/training",
    "/completed-training",
    "/upcoming-training",
    "/examinations",
    "/reports-analytics",
    "/performance-nav",
    "/completed_examinations",
    "/upcoming-examinations",
    "/salary-increments",
    "/leave-management",
    "/leave-info",
    "/loan-nav",
    "/view-loan-info",
    "/review-guarantors",
    "/loan-eligible",
    "/leave-reports",
    "/approval1",
    "/approval2",
    "/approval1-info",
    "/approval2-info",
    "/approval1-user",
    "/approval2-user",
  ];
  const isSidebarRequired = validRoutes.some((route) =>
    new RegExp(`^${route.replace(/:[^/]+/, "[^/]+")}$`).test(location.pathname)
  );
  return isSidebarRequired ? (
    <>
      <div className="SideBar">
        <SideMenu />
      </div>
      <div className="App-content">{children}</div>
    </>
  ) : (
    <div>{children}</div>
  );
};
function App() {
  return (
    <div className="app">
      <Router>
        <SidebarChecker>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/emp-onboarding" element={<Onboard />} />
            <Route path="/emp-nav" element={<Emp_Nav />} />
            <Route path="/view-export-table" element={<View_Export_Table />} />
            <Route path="/history-log" element={<History_Log />} />
            <Route
              path="/user-profile/:employee_no"
              element={<User_Profile />}
            />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/in-and-out-regular" element={<Inandout_Regular />} />
            <Route path="/in-and-out-shift" element={<Inandout_Shift />} />
            <Route path="/leaves" element={<Leave />} />
            <Route
              path="/lateness-permission"
              element={<Lateness_Permission />}
            />
            <Route path="/leave-doc" element={<Leave_Doc />} />
            <Route path="/over-time" element={<OT />} />
            <Route path="/payments-menu" element={<Payments_Menu />} />
            <Route
              path="/payment_details/:value/:name"
              element={<Payment_Details />}
            />
            <Route path="/training" element={<Training />} />
            <Route
              path="/completed-training"
              element={<Completed_Training />}
            />
            <Route path="/upcoming-training" element={<Upcoming_Trainings />} />
            <Route path="/examinations" element={<Examinations_Card />} />
            <Route path="/reports-analytics" element={<Reports_Analytics />} />
            <Route path="/performance-nav" element={<Performance_Nav />} />
            <Route
              path="/completed_examinations"
              element={<Completed_Examinations />}
            />
            <Route
              path="/upcoming-examinations"
              element={<Upcoming_Examinations />}
            />
            <Route path="/salary-increments" element={<Salary_Increments />} />
            <Route path="/leave-management" element={<Leave_Management />} />
            <Route path="/leave-info" element={<Leave_Info />} />
            <Route path="/loan-nav" element={<Loan_Nav />} />
            <Route path="/view-loan-info" element={<View_Loan_Info />} />
            <Route path="/review-guarantors" element={<Review_Guarantors />} />
            <Route path="/loan-eligible" element={<Loan_Elibility />} />
            <Route path="/leave-reports" element={<Leave_Reports />} />
            <Route path="/approval1" element={<Approval1 />} />
            <Route path="/approval1-user" element={<Approval1user />} />
            <Route path="/approval2-user" element={<Approval2user />} />

            <Route path="/approval2" element={<Approval2 />} />
            <Route path="/approval1-info" element={<Approval1_Info />} />
            <Route path="/approval2-info" element={<Approval2_Info />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </SidebarChecker>
      </Router>
    </div>
  );
}
export default App;
