import React, { useState } from "react";
import { Link } from "react-router-dom";

const View_Loan_Info = () => {
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="mt-6 ml-6">
        <p className="text-headerColor text-[30px] font-semibold">
          View Loan Information
        </p>
      </div>
      <div className="flex justify-between gap-2">
        <div>
          <div>
            <div>
              <div className="flex flex-col">
                <label
                  htmlFor="nic_number"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                ></label>
                <select
                  id={``}
                  name={``}
                  autoComplete=""
                  className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                >
                  <option className="font-sans-bold ">Department</option>
                  <option>Dep 01</option>
                  <option>Dep 02</option>
                  <option>Dep 03</option>
                </select>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <div className="flex flex-col">
                  <label
                    htmlFor="nic_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  ></label>
                  <select
                    id={``}
                    name={``}
                    autoComplete=""
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  >
                    <option className="font-sans-bold ">All</option>
                    <option>Training #</option>
                    <option>Training #</option>
                    <option>Training #</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
              <form class="form relative">
                <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                  <svg
                    class="w-5 h-5 text-gray-700"
                    aria-labelledby="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    height="16"
                    width="17"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="1.333"
                      stroke="currentColor"
                      d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                    ></path>
                  </svg>
                </button>
                <input
                  type="text"
                  required=""
                  placeholder="Search..."
                  class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                />
              </form>
            </div>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-2 grid-flow-col gap-4 mr-[50px]">
            <div className="bg-white rounded-[20px] text-headerColor font-sans font-semibold h-[200px] ml-r w-[400px]">
              <p className="text-headerColor text-[30px] font-semibold ml-6 mt-6">
                {" "}
                Review{" "}
              </p>
              <p className="text-headerColor text-[20px] font-sans font-semibold ml-6 ">
                Already Assigned{" "}
              </p>
              <p className="font-sans text-headerColor text-[20px] ml-6">
                Guarantors
              </p>

              <Link>
                <div className="flex justify-end mr-3">
                  <button className="bg-[#183D58] text-white p-2  w-[80px] rounded-[20px] ">
                    {" "}
                    View
                  </button>
                </div>
              </Link>
            </div>
            <div className="bg-white rounded-[20px]">
              <div className="font-sans ml-6 mt-6 text-[30px] font-semibold text-headerColor ">
                <p>Check</p>
                <p>Eligibility</p>
              </div>
              <Link to="/loan-eligible">
                <div className="flex justify-end mr-3">
                  <button className="bg-[#183D58] text-white p-2 font-semibold font-sans mt-[18px]  w-[80px] rounded-[20px] ">
                    View
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-[50px] rounded-xl">
        <table className="table-auto border-none w-[95%]  rounded-2xl border-2  ">
          <thead>
            <tr className="bg-white rounded-xl ">
              <th className="text-primary px-4 py-2 w-[16%] ">#</th>
              <th className="text-primary px-4 py-2 w-[16%] ">EMP NO</th>
              <th className="text-primary px-4 py-2 w-[16%]  ">
                Employee Name
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">
                Monthly Payment
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">
                Remaining Months
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bg-white px-4 py-2 w-[16%]">test</td>
              <td className="bg-white px-4 py-2 w-[16%]">test</td>
              <td className="bg-white px-4 py-2 w-[16%]">test</td>
              <td className="bg-white px-4 py-2 w-[16%]">test</td>
              <td className="bg-white px-4 py-2 w-[16%]">test</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default View_Loan_Info;
