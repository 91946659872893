import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AssignDatePopup from "./assign_date";
import ViewDatePopup from "./view_date";
const Leave_Nav = () => {
  const [leaveCounts, setLeaveCounts] = useState({});
  const navigate = useNavigate();
  const [isAssignPopupOpen, setIsAssignPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);

  // Example toggle function for Assign Date
  const toggleAssignPopup = () => {
    setIsAssignPopupOpen(!isAssignPopupOpen);
  };

  // For View Restricted Dates
  const toggleViewPopup = () => {
    setIsViewPopupOpen(!isViewPopupOpen);
  };

  useEffect(() => {
    fetch("https://back-demo-british.talentfort.live/v1/hris/leave/getleave")
      .then((response) => response.json())
      .then((data) => {
        // Creating a counts object based on the data fetched
        const counts = data.reduce((acc, curr) => {
          // Use the API's leave_type directly as the key
          const key = curr.leave_type;
          acc[key] = (acc[key] || 0) + 1;
          return acc;
        }, {});
        console.log("Counts:", counts); // Debugging to verify counts
        setLeaveCounts(counts);
      })
      .catch((error) => console.error("Error fetching leave counts:", error));
  }, []);

  const handleCardClick = (leaveType) => {
    navigate("/leave-info", { state: { leaveType } });
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="mt-6 ml-6">
        <p className="text-headerColor text-[28px] font-semibold font-sans">
          Leave Management
        </p>
      </div>
      <div className="flex  gap-[2%] m-[5%] ">
        <div className=" mt-[2%] bg-white w-[45%] h-[70px] rounded-[20px] flex items-center ">
          <p className=" text-headerColor text-[20px] font-semibold font-sans ml-5 ">
            Assign Date Restrictions
          </p>
          <div>
            <p
              className="bg-[#071C50] cursor-pointer w-[120px] flex justify-center rounded-[15px]  ml-[150px]  mr-5 text-white font-bold font-sans p-1"
              onClick={toggleAssignPopup}
            >
              Click Here
            </p>
          </div>
        </div>
        {isAssignPopupOpen && (
          <AssignDatePopup onClose={() => setIsAssignPopupOpen(false)} />
        )}
        <div className=" mt-[2%] bg-white w-[45%] h-[70px] rounded-[20px] flex items-center ">
          <p className=" text-headerColor text-[20px] font-semibold font-sans ml-5 ">
            View Restricted Dates{" "}
          </p>
          <div>
            <p
              className="bg-[#071C50] cursor-pointer w-[120px] flex justify-center rounded-[15px]  ml-[200px] mr-5 text-white font-bold font-sans p-1"
              onClick={toggleViewPopup}
            >
              Click Here
            </p>
          </div>
        </div>
        {isViewPopupOpen && (
          <ViewDatePopup onClose={() => setIsViewPopupOpen(false)} />
        )}
      </div>

      <div className="mt-5 ml-[5%] flex">
        <div className="grid grid-cols-3 grid-rows-1 grid-flow-row gap-[25px] w-[1050px]">
          {["Annual-Leave", "Casual-Leave", "Medical-Leave", "Short-Leave"].map(
            (leaveType) => (
              <Card
                key={leaveType}
                leaveType={leaveType.replace(/-/g, " ")} // Replace hyphens for display purposes
                count={leaveCounts[leaveType] || 0}
                onClick={handleCardClick}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

// Card Component
const Card = ({ leaveType, count, onClick }) => {
  return (
    <div className="bg-white rounded-[30px]">
      <div className="ml-5 font-sans">
        <p className="text-headerColor font-semibold text-[28px] mt-3">
          {leaveType}
        </p>
      </div>
      <div className="flex justify-between items-center p-3">
        <div className="bg-[#183D58] w-[60px] font-bold h-[60px] text-center text-white mt-9 rounded-[20px] ml-5 flex justify-center items-center">
          {count}
        </div>
        <div onClick={() => onClick(leaveType)}>
          <p className="bg-gray-300 cursor-pointer w-[70px] flex justify-center rounded-[20px] mr-5 mt-8 text-black font-bold font-sans p-2">
            View
          </p>
        </div>
      </div>
    </div>
  );
};

export default Leave_Nav;
