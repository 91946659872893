import React from "react";

import { Link } from "react-router-dom";

const emp_nav = () => {
  const empData = [
    {
      id: 1,
      header: "Employee Quick Onboard",

      headerClasses: "text-black",
      linkTo: "/emp-nav",
      linkTo: "/emp-onboarding",
    },
    {
      id: 2,
      header: "View Export Details",

      headerClasses: "text-black",
      linkTo: "/view-export-table",
    },
    {
      id: 5,
      header: "History Log Edits",

      headerClasses: "text-black",
      linkTo: "/history-log",
    },
  ];

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80] ">
        Employee
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6 ">
        {empData.map((card) => (
          <Link key={card.id} to={card.linkTo}>
            <div
              key={card.id}
              className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between"
            >
              <div>
                {/* Conditional rendering based on header words */}
                {card.header.split(" ").map((word, index) => (
                  <h2 key={index} className="text-[20px] font-semibold mb-1">
                    {word}
                  </h2>
                ))}
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-[#071C50] w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]">
                  Click Here
                </button>

                <div className="bg-[#071C50] w-[13px] h-[37px] rounded-xl"></div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default emp_nav;
