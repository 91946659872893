import React, { useState } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Return from "../../../../Assets/icons/back-arrow.png";

function InAndOutTimeShift() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const navigate = useNavigate(); // Hook for navigation

  // Dummy data for demonstration
  const data = [
    {
      empNo: "001",
      fullName: "John Doe",
      inTime: "09:00 AM",
      outTime: "05:00 PM",
    },
    // Add more dummy data as needed
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filtering data based on search query
  const filteredData = data.filter(
    (item) =>
      item.empNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReset = () => {
    setSearchQuery("");
  };

  const handleTrackNow = () => {
    navigate("/in-and-out-regular"); // Navigate to "/in-and-out-regular" route
  };

  const handleBack = () => {
    navigate("/attendance");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Shift basis Employees
      </p>

      <div className="mt-5 ml-6 ">
        <img
          onClick={handleBack}
          src={Return}
          alt="return-icon"
          className="h-10 w-10 cursor-pointer"
        />
      </div>

      <div className="flex justify-between mt-[80px]">
        {/* input fields */}
        <div>
          <div className="ml-6 flex gap-[40px]">
            <div>
              <p className="text-headerColor font-bold mb-2">Start Date</p>
              <input
                type="date"
                className="w-[217px] h-[55px] rounded-[22px]"
              ></input>
            </div>
            <div>
              <p className="text-headerColor font-bold mb-2">End Date</p>
              <input
                type="date"
                className="w-[217px] h-[55px] rounded-[22px]"
              ></input>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Employee Code"
              />
            </div>
            <div>
              <p className="text-headerColor font-bold">Reset</p>
            </div>
          </div>

          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <select
                className="w-[480px] h-[55px] rounded-[22px] bg-white"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Sort by Department
                </option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
            </div>
          </div>
        </div>

        <div className="ml-[35%]">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6  ">
            <div
              key=""
              className="bg-white rounded-[32px]  w-[350px] h-[200px] p-4 shadow-md flex flex-col justify-between"
            >
              <div>
                <h2 className="text-[25px] font-semibold mb-1 font-sans">
                  Regular{" "}
                </h2>
                <h2 className="text-[25px] font-semibold mb-1 font-sans">
                  Employees
                </h2>
              </div>
              <div className="flex justify-between items-center">
                {/* Replace <Link> with onClick handler */}
                <button
                  className="bg-[#183D58] w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleTrackNow}
                >
                  Track Now{" "}
                </button>
                <div className="bg-[#183D58] w-[13px] h-[37px] rounded-xl"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <label className="font-semibold ml-[5%]">Attendance Sheet</label>
      <div className="ml-[6%]">
        <hr className="line border-t border-gray-300 w-[5%]  "></hr>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[85%] ">
          <thead>
            <tr className="bg-white text-[20px]">
              <th className="text-primary px-4 py-2 w-[20%]">Emp NO</th>
              <th className="text-primary px-4 py-2 w-[40%]">Full Name</th>
              <th className="text-primary px-4 py-2 w-[20%]">In-Time</th>
              <th className="text-primary px-4 py-2 w-[20%]">Out-Time</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td className="bg-white px-4 py-2 w-[20%]">{item.empNo}</td>
                <td className="bg-white px-4 py-2 w-[40%]">{item.fullName}</td>
                <td className="bg-white px-4 py-2 w-[20%]">{item.inTime}</td>
                <td className="bg-white px-4 py-2 w-[20%]">{item.outTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md "
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
}

export default InAndOutTimeShift;
