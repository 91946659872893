import React from "react";
import { Link } from "react-router-dom";

function Attendance() {
  const attendance = [
    {
      id: 1,
      header: "In-Time & ",
      header2: "Out-Time",
      headerClasses: "text-black  ",
      linkTo: "/in-and-out-regular",
    },
    {
      id: 2,
      header: " Leaves",
      headerClasses: "text-black",
      linkTo: "/leaves",
    },
    {
      id: 3,
      header: "Permission Grant",
      header2: " (Lateness)",
      headerClasses: "text-black",
      linkTo: "/lateness-permission",
    },
    {
      id: 4,
      header: "Over Time",
      header2: "(OT)",
      headerClasses: "text-black",
      linkTo: "/over-time",
    },
  ];
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80] ">
        Time & Attendance Management
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2.5%] mx-6 font-sans ">
        {attendance.map((card) => (
          <Link key={card.id} to={card.linkTo}>
            <div
              key={card.id}
              className="bg-white rounded-[32px] h-[200px] w-[374px] p-4 shadow-md flex flex-col justify-between"
            >
              <div>
                {/* Conditional rendering based on header words */}
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  {card.header}{" "}
                </h2>
                <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                  {card.header2}{" "}
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-[#183D58] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>

                <div className="bg-[#183D58] w-[13px] h-[37px] rounded-xl"></div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Attendance;
