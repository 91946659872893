import React, { useState, useEffect } from "react";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import Apply_Leave from "./apply_leave";
import Update_Leave from "./update_leave";
import PDF from "../../../../Assets/icons/png.png"; // Assuming you have a PDF icon image

const ConfirmationModal = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
      <div className="relative z-50 w-auto max-w-sm mx-auto my-6">
        <div className="relative bg-white border-0 rounded-[30px] shadow-lg outline-none focus:outline-none">
          <div className="relative p-6">
            <div>
              <button
                className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 mt-3 mr-3 rounded-[20px]"
                onClick={onCancel} // Call handleClosePopup to close the popup
              >
                X
              </button>
            </div>
            <div className="flex justify-center">
              <div className="bg-[#183D58] w-[48px] h-[11px] rounded-[22px]"></div>
            </div>
            <p className="my-4 text-blueGray-500 text-[30px] text-headerColor leading-relaxed flex justify-center font-bold">
              {message}
            </p>
          </div>
          <div className="flex items-center justify-center gap-[100px] p-6">
            <div>
              <button
                className="text-black bg-[#E2E2E2] rounded-[22px] font-bold  px-6 py-2 text-[22px] outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onCancel}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="bg-[#E0EEDF] text-black active:bg-green-600 font-bold text-[22px] px-6 py-2 rounded-[22px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Leave() {
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // You can adjust the number of items per page here
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submittedFiles, setSubmittedFiles] = useState([]);
  const [data, setData] = useState([
    {
      type: "Short Leave",
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },

    // Add more dummy data as needed
  ]);
  const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of the item being deleted
  const [leaveData, setLeaveData] = useState([]);

  const handleDeleteClick = (index) => {
    setShowConfirmationModal(true);
    setDeleteIndex(index); // Set the index of the item being deleted
  };

  const handleDeleteConfirmed = () => {
    const newData = [...data];
    newData.splice(indexOfFirstItem + deleteIndex, 1); // Remove the item from the newData array
    setData(newData); // Update the state with the modified newData array
    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const [editIndex, setEditIndex] = useState(null); // Step 2: Create state to track edit mode
  const [editLeavePopupOpen, setEditLeavePopupOpen] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== "application/pdf") {
      setShowAlertMessage(true);
    } else {
      setShowAlertMessage(false);
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const handleSubmit = () => {
    // Placeholder for submission logic
    setSubmittedFiles(selectedFiles);
    setSelectedFiles([]);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleEditClick = (index) => {
    setEditIndex(index); // Set the edit index when edit button is clicked
    setEditLeavePopupOpen(true); // Open the EditLeave popup
  };

  const toggleEditLeavePopup = () => {
    setEditLeavePopupOpen(!editLeavePopupOpen); // Toggle the EditLeave popup visibility
  };

  useEffect(() => {
    fetch("https://back-demo-british.talentfort.live/v1/hris/leave/getleave")
      .then((response) => response.json())
      .then((data) => {
        setLeaveData(data);
        setSubmittedFiles(new Array(data.length).fill(null));
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Leave Management
      </p>
      <div className="flex justify-between items-center">
        <div className="flex flex-col font-poppins font-bold ml-6 mt-4 mb-[2%]">
          <form className="form relative">
            <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
              <svg
                className="w-5 h-5 text-gray-700"
                aria-labelledby="search"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16"
                width="17"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="1.333"
                  stroke="currentColor"
                  d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              required=""
              placeholder="Search..."
              onChange={""}
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            />
          </form>
        </div>

        <div>
          <Apply_Leave />
        </div>
      </div>
      <label className="font-semibold ml-[5%]">Leave Sheet</label>
      <div className="ml-[5%]">
        <hr className="line border-t border-gray-300 w-[5%]"></hr>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white ">
              <th className="text-primary px-4 py-2 ">Type</th>
              <th className="text-primary px-4 py-2 ">Emp NO</th>
              <th className="text-primary px-4 py-2 ">Full Name</th>
              <th className="text-primary px-4 py-2 ">Requested Date</th>
              <th className="text-primary px-4 py-2 ">View Document</th>
              <th className="text-primary px-4 py-2 ">Action</th>
            </tr>
          </thead>
          <tbody>
            {leaveData.map((item, index) => (
              <tr key={index}>
                <td className="bg-white px-4 py-2">{item.leave_type}</td>
                <td className="bg-white px-4 py-2">{item.employee_no}</td>
                <td className="bg-white px-4 py-2">{item.employee_fullname}</td>
                <td className="bg-white px-4 py-2">
                  <input
                    type="date"
                    required
                    defaultValue={item.requested_date}
                    className="input rounded-full h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  />
                </td>
                <td className="bg-white px-4 py-2">
                  {item.leave_file_path ? (
                    <div className="h-8 mt-3 flex items-center">
                      <img src={PDF} alt="pdf-icon" className="w-8 h-8 mr-2" />
                      <p className="font-sans text-sm">
                        {item.leave_file_name}
                      </p>
                    </div>
                  ) : (
                    <div className="flex gap-3 items-center">
                      <div>
                        <input
                          className="h-10 rounded-[30px] border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#183D58] file:text-white file:text-sm file:font-medium file:h-10"
                          type="file"
                          id={`picture${index}`}
                          accept="application/pdf"
                          onChange={(e) => handleFileChange(e, index)}
                        />
                      </div>
                      <div>
                        <button
                          className="bg-yellow-500 rounded-[15px] text-white p-1 text-sm"
                          onClick={() => handleSubmit(index)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </td>
                <td className="bg-white px-4 py-2">
                  <button
                    onClick={() => handleEditClick(index)}
                    className="text-black font-bold py-2 px-4 rounded mr-2"
                  >
                    <CiEdit />
                  </button>
                  <button
                    onClick={() => handleDeleteClick(index)}
                    className="text-red-600 font-bold py-2 px-4 rounded"
                  >
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`${
              currentPage === index + 1 ? "bg-gray-300" : "bg-white"
            } text-primary px-4 py-2 mx-1 rounded-md`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md "
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          message="Confirm Your Deletion"
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}

      {editLeavePopupOpen && <Update_Leave />}
    </div>
  );
}

export default Leave;
