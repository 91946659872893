import React, { useState } from "react";
import Leave_Reports from "./leave_reports_info";

const ReportsAnalytics = () => {
  const [selectedCard, setSelectedCard] = useState(""); // State to manage selected card

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="text-headerColor text-[22px] font-semibold font-sans mt-6 ml-6">
          Reports & Analytics
        </p>
      </div>
      <div className="grid grid-cols-3 grid-flow-col gap-8 ml-6 mt-6 px-[100px]">
        {/* Card 1 */}
        <div className="bg-white rounded-[32px] p-6">
          <div>
            <p className="font-sans text-[30px] font-semibold">Monthly Leave</p>
            <p className="text-[30px] font-sans font-semibold">Reports</p>
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => handleCardClick("Monthly Leave")}
              className="bg-[#183D58] p-3 text-white rounded-[20px] mt-3 font-semibold"
            >
              View
            </button>
          </div>
        </div>

        {/* Card 2 */}
        <div className="bg-white rounded-[32px] p-6">
          <div>
            <p className="font-sans text-[30px] font-semibold">Medical Leave</p>
            <p className="text-[30px] font-sans font-semibold">Reports</p>
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => handleCardClick("Medical Leave")}
              className="bg-[#183D58] p-3 text-white rounded-[20px] mt-3 font-semibold"
            >
              View
            </button>
          </div>
        </div>

        {/* Card 3 */}
        <div className="bg-white rounded-[32px] p-6">
          <div>
            <p className="font-sans text-[30px] font-semibold">
              Incentive <span className="text-sm">&</span>
            </p>
            <p className="text-[25px] font-sans font-semibold">
              Attendance Allowance Reports
            </p>
          </div>
          <div className="flex justify-end mt-[-10%]">
            <button
              onClick={() => handleCardClick("Incentive & Attendance")}
              className="bg-[#183D58] p-3 text-white rounded-[20px] mt-3 font-semibold"
            >
              View
            </button>
          </div>
        </div>

        {/* Popup/Modal */}
        {selectedCard && (
          <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
            <Leave_Reports
              cardName={selectedCard}
              onClose={() => setSelectedCard("")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportsAnalytics;
