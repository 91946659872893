import React, { useState } from "react";

function Calendar({ leaveDates }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  const dayOfWeek = firstDayOfMonth.getDay();

  const changeMonth = (delta) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + delta, 1)
    );
  };

  return (
    <div className="flex flex-col items-center p-5">
      <div className="flex items-center justify-between mb-4 w-full max-w-md px-4 py-2 bg-white rounded ">
        <button
          onClick={() => changeMonth(-1)}
          className="p-2 text-black rounded hover:bg-[#252C58] hover:text-white"
        >
          &lt;
        </button>
        <span className="text-lg font-semibold">
          {months[currentDate.getMonth()]} {currentDate.getFullYear()}
        </span>
        <button
          onClick={() => changeMonth(1)}
          className="p-2 text-black rounded hover:bg-[#252C58]  hover:text-white"
        >
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 w-full max-w-md px-4 py-2 bg-white rounded h-[250px] ">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-medium">
            {day}
          </div>
        ))}
        {Array.from({ length: dayOfWeek })
          .fill(null)
          .map((_, index) => (
            <div key={index} className="h-8"></div>
          ))}
        {Array.from({ length: daysInMonth }).map((_, i) => {
          const day = i + 1;
          const date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            day
          );
          const isLeaveDate = leaveDates.some(
            (d) => d.toDateString() === date.toDateString()
          );
          return (
            <div
              key={i}
              className={`h-8 flex justify-center items-center cursor-pointer ${
                isLeaveDate ? "bg-[#252C58] text-white" : "text-gray-700"
              }`}
            >
              {day}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Calendar;
