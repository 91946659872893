import React, { useState } from "react";
import Contact from "../../../Assets/icons/contact.png";

const Contact_popup = () => {
  // Function to handle contact
  const handleContact = () => {
    setShowPopup(true);
  };
  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [showPopup, setShowPopup] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (empNo) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(empNo)) {
        return prevSelectedRows.filter((rowId) => rowId !== empNo);
      } else {
        return [...prevSelectedRows, empNo];
      }
    });
  };

  const employees = [
    { empNo: "EMP001", empName: "John Doe" },
    { empNo: "EMP002", empName: "Jane Smith" },
    { empNo: "EMP003", empName: "Alice Johnson" },
    { empNo: "EMP004", empName: "Bob Brown" },
  ];

  return (
    <div>
      <div>
        <div className="bg-white rounded-[22px] p-5 w-[10rem] h-[8rem] mr-5">
          <img
            className="w-[70px] h-[70px] ml-5"
            src={Contact}
            alt="contact-logo"
            onClick={handleContact}
          />
          <p className="font-sans font-bold text-center text-[#797C80]">
            Contact
          </p>
        </div>
      </div>

      {/* Popup box */}
      {showPopup && (
        <div className="fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full h-screen overflow-y-auto">
          <div className="bg-[#F3F8FF] p-10 rounded-lg relative overflow-y-auto h-screen">
            <button
              className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded-full"
              onClick={handleClosePopup} // Call handleClosePopup to close the popup
            >
              X
            </button>
            <div className="flex justify-start">
              <div>
                <div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="nic_number"
                      className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                    ></label>
                    <select
                      id={``}
                      name={``}
                      autoComplete=""
                      className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    >
                      <option className="font-sans-bold ">Department</option>
                      <option>Dep 01</option>
                      <option>Dep 02</option>
                      <option>Dep 03</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
                  <form class="form relative">
                    <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                      <svg
                        class="w-5 h-5 text-gray-700"
                        aria-labelledby="search"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        height="16"
                        width="17"
                      >
                        <path
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          stroke-width="1.333"
                          stroke="currentColor"
                          d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                        ></path>
                      </svg>
                    </button>
                    <input
                      type="text"
                      required=""
                      placeholder="Search..."
                      class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <p className="text-headerColor font-sans text-[22px] font-semibold">
                Enter Mail Details
                <hr class="line border-t border-gray-300 w-[25%]  "></hr>
              </p>
            </div>

            <div className="font-sans">
              <div>
                {" "}
                <label className="font-semibold">Subject :</label>
                <input
                  className="ml-4 border border-1px solid-gray rounded-[30px] p-2"
                  placeholder="Enter Subject"
                />
              </div>

              <div className="mt-4">
                <div className="flex">
                  <div>
                    <label className="font-semibold">Body :</label>
                  </div>
                  <div>
                    <textarea
                      placeholder="Enter Mail Body"
                      className=" w-[300px] h-[90px] ml-[30px]"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div>
                  <p className="font-semibold text-headerColor font-sans text-[22px]">
                    Select Recipient(s)
                    <hr class="line border-t border-gray-300 w-[25%]  "></hr>
                  </p>
                </div>

                <div className="overflow-x-hidden overflow-y-auto mt-5 ">
                  <table className="min-w-full table-auto border border-none rounded-[20px]">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="p-2 ">Select</th>
                        <th className="p-2">Emp no</th>
                        <th className="p-2">Emp name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((employee) => (
                        <tr key={employee.empNo}>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(employee.empNo)}
                              onChange={() =>
                                handleCheckboxChange(employee.empNo)
                              }
                            />
                          </td>
                          <td className="p-2">{employee.empNo}</td>
                          <td className="p-2">{employee.empName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-3">
              <button className="font-sans bg-[#183D58] text-white p-2 rounded-[20px]">
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact_popup;
