import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineClear } from "react-icons/ai";

const PaymentDetails = () => {
  const { name } = useParams();
  const { value } = useParams();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [allowanceData, setAllowanceData] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.csv)$/i; // Regular expression to check for CSV files
    const fileNameMatchesValue = file.name === `${value}.csv`;

    if (!allowedExtensions.exec(file.name)) {
      // If the file extension is not .csv
      setShowAlertMessage(true); // Show alert message
      setAlertMessage("Please upload only CSV files."); // Set alert message content
    } else if (!fileNameMatchesValue) {
      // If the file name does not match the specified value
      setShowAlertMessage(true); // Show alert message
      setAlertMessage(
        `Please make sure the select correct file, File name should be ${value}.csv`
      ); // Set alert message content
    } else {
      // If the file meets the criteria
      setUploadedFile(file);
      setFileSelected(true);
      setShowAlertMessage(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("allowance_type", value);

      console.log("FormData:", formData);

      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/payroll/uploadallowance",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("File uploaded successfully");
        // Clear the uploaded file and file selection state after successful upload
        setUploadedFile(null);
        setFileSelected(false);
        // Provide user feedback, e.g., show a success message
      } else {
        console.error("Failed to upload file");
        // Provide user feedback, e.g., show an error message
      }
    } catch (error) {
      console.error("Error occurred while uploading file:", error);
      // Provide user feedback, e.g., show an error message
    }
  };
  const handleClearClick = async () => {
    const url = `https://back-demo-british.talentfort.live/v1/hris/payroll/clearallowance?allowance_type=${value}`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // Include other headers as required, like authorization
      },
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        console.log("Clearance successful:", data);
        alert("Allowance cleared successfully!");
      } else {
        throw new Error("Failed to clear allowance");
      }
    } catch (error) {
      console.error("Error clearing allowance:", error);
      alert("Error clearing allowance.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://back-demo-british.talentfort.live/v1/hris/payroll/getallowance?allowance_type=${value}`
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Data:", data);
          setAllowanceData(data);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [value]);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          {name} Allowance
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div>
                <div className="flex flex-col">
                  <select
                    id="branchSelect"
                    name="branch"
                    autoComplete="off"
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  >
                    <option className="font-sans-bold">Branch</option>
                    <option>Dep 01</option>
                    <option>Dep 02</option>
                    <option>Dep 03</option>
                  </select>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <div className="flex flex-col">
                    <select
                      id="departmentSelect"
                      name="department"
                      autoComplete="off"
                      className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    >
                      <option className="font-sans-bold">Department</option>
                      <option>Training #</option>
                      <option>Training #</option>
                      <option>Training #</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
                <div className="relative">
                  <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                    <svg
                      className="w-5 h-5 text-gray-700"
                      aria-labelledby="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      height="16"
                      width="17"
                    >
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.333"
                        stroke="currentColor"
                        d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      ></path>
                    </svg>
                  </button>
                  <input
                    type="text"
                    required=""
                    placeholder="Search..."
                    className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  />
                </div>
              </div>
            </div>
            {/* card */}
            <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
              <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
                <p>Upload CSV</p>
                <p>File</p>
              </div>

              <div className="flex items-center justify-between">
                <label
                  htmlFor="fileInput"
                  className={`bg-[#183D58] mt-5 ml-4 p-2 rounded-[32px] text-white cursor-pointer ${
                    fileSelected ? "hidden" : ""
                  }`}
                >
                  Upload
                </label>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <button
                  type="button"
                  className={`bg-[#183D58] mt-5 ml-4  p-2 rounded-[32px] text-white cursor-pointer ${
                    !fileSelected ? "hidden" : ""
                  }`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                {uploadedFile && (
                  <div className="flex items-center mt-2 ml-6  justify-end">
                    <div>{uploadedFile.name}</div>
                  </div>
                )}
                {showAlertMessage && (
                  <div className="text-red-600 text-sm2 mt-2 ml-5">
                    {alertMessage}
                  </div>
                )}
                <div className="mr-5">
                  <div className="bg-[#183D58] rounded-[32px] h-[37px] w-[13px]"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex justify-end mr-[5%] mt-5 font-bold mb-5 sm:text-l"
            onClick={handleClearClick}
          >
            <div className="bg-[#797C80] ml-4 p-2 rounded-[32px] text-[#F9FFBA] cursor-pointer w-[168.46px] h-[45px] flex items-center justify-center gap-2">
              <AiOutlineClear className="w-[25px] h-[25px] " />
              <label htmlFor="fileInput cursor-pointer ">Clear All</label>
            </div>
          </div>
          <div className="flex flex-col font-sans font-bold ml-6 mt-2 rounded-xl">
            <table className="table-auto border-none w-[95%]">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary px-4 py-2">Emp NO</th>
                  <th className="text-primary px-4 py-2">Name with Initials</th>
                  <th className="text-primary px-4 py-2">
                    employee_department
                  </th>
                  <th className="text-primary px-4 py-2">
                    employee_designation
                  </th>
                  <th className="text-primary px-4 py-2">Amount </th>
                </tr>
              </thead>
              <tbody>
                {allowanceData.map((item, index) => (
                  <tr key={index} className="bg-white">
                    <td className="px-4 py-2">{item.employee_no}</td>
                    <td className="px-4 py-2">{item.employee_name_initial}</td>
                    <td className="px-4 py-2">{item.employee_department}</td>
                    <td className="px-4 py-2">{item.employee_designation}</td>
                    <td className="px-4 py-2">{item.Amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
