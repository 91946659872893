import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ERP1 from "../../Assets/icons/ERP1.png";
import erpLogo from "../../Assets/icons/erpLogo.png";

function Login() {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Login Submitted:", { email, password });
    event.preventDefault();
    window.location.href = "/pos/pos"; // Using window.location to navigate
    // Handle login logic here
  };
  return (
    <div className="flex gap-50 items-center  bg-gray-100 overflow-hidden">
      <div className="mt-[-10%] mr-2">
        <img
          src={erpLogo}
          alt="Logo"
          className="w-[500px] h-[910px] rounded-[179px] ml-20 mr-20 "
          // style={{ maxHeight: "80vh", maxWidth: "100%" }}
        />
      </div>
      <div className="w-1/2 p-8">
        <div className="">
          <img
            src={ERP1}
            alt="Logo"
            className="w-[200px] mb-5"
            // style={{ maxHeight: "80vh", maxWidth: "100%" }}
          />
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Login</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 px-4 py-2 w-[512px] border rounded-[63px] focus:outline-none focus:border-blue-400"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 px-4 py-2 w-[512px] border rounded-[63px] focus:outline-none focus:border-blue-400"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input type="checkbox" id="remember" className="mr-2" />
              <label htmlFor="remember" className="text-sm text-gray-600">
                Remember me
              </label>
            </div>
          </div>
          <div className=" mt-[80px]">
            <button
              type="submit"
              className="w-[512px] bg-lime-500 text-white py-2 hover:bg-lime-600 focus:outline-none rounded-[63px] mt-10"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;
