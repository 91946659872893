import React from "react";
import { Link } from "react-router-dom";
import Employee from "../../../Assets/icons/EMPLOYEE IMAGE 1.png";
import Work from "../../../Assets/icons/Work locations image 1.png";
import Hierachy from "../../../Assets/icons/Hierarchy image 2 1.png";
import Salary from "../../../Assets/icons/Salary image 2.png";
import Money from "../../../Assets/icons/Money 2 image 1.png";
import Contact_Popup from "./contact_popup";

const cardsData = [
  {
    id: 1,
    header: "Employee",
    imgSrc: Employee,
    count: 10,
    headerClasses: "text-black",
    imgStyles: {
      marginLeft: "200px",
      marginTop: "-80px",
      width: "240px",
      height: "200px",
    },
  },
  {
    id: 2,
    header: "Working Locations",
    imgSrc: Work,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      marginTop: "-80px",
      width: "200px",
      height: "200px",
      marginRight: "50px",
      marginLeft: "200px",
    },
  },
  {
    id: 3,
    header: "Departments",
    imgSrc: Hierachy,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      marginTop: "-130px",
      marginLeft: "120px",
      width: "250px",
      height: "250px",
      marginRight: "40px",
    },
  },
  // {
  //   id: 4,
  //   header: "Training Programs",
  //   imgSrc: Training,
  //   count: 15,
  //   headerClasses: "text-black",
  //   imgStyles: {
  //     marginTop: "-80px",
  //     marginLeft: "190px",
  //     width: "240px",
  //     height: "200px",
  //     marginRight: "50px",
  //   },
  // },
  {
    id: 5,
    header: "Overall Payroll Cost",
    imgSrc: Salary,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      marginLeft: "200px",
      marginTop: "-80px",
      width: "200px",
      height: "200px",
      marginRight: "50px",
    },
  },
  {
    id: 6,
    header: "Average Salary Increment",
    imgSrc: Money,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      marginTop: "-80px",
      width: "200px",
      height: "200px",
      marginRight: "-50px",
    },
  },
];

const Card = () => {
  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen overflow-x-hidden">
      <div className="flex  items-center justify-between mt-3">
        <div>
          <p className="text-left text-[40px] font-sans font-semibold ml-6 mt-6 text-headerColor">
            Dashboard
          </p>
        </div>

        <div>
          <Contact_Popup />
        </div>
      </div>

      <div>
        <div className="grid grid-cols-3 gap-[60px] p-2 justify-center mt-6 ml-[40px]">
          {cardsData.map((card) => (
            <Link key={card.id} to={card.linkTo}>
              <div className="bg-white w-[400px] h-[240px] rounded-[40px] shadow-md p-2">
                <h2
                  className={`text-[20px] font-sans font-semibold ml-4 opacity-460 ${card.headerClasses}`}
                >
                  {card.header}
                </h2>
                <img
                  src={card.imgSrc}
                  alt={card.header}
                  className={`${card.imgClasses} mx-auto mb-4 gap-4 `}
                  style={card.imgStyles}
                />
                <div>
                  <p className="text-[20px] ml-5 bg-[#183D58] text-[#FFFF] font-bold font-sans p-2 rounded-[30px] max-w-[100px] text-center">
                    {card.count}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div className="grid grid-cols-2 gap-4 mt-10 ml-[60px] p-2 ">
          <div className="bg-white rounded-lg cursor-pointer">
            <div className="ml-10 flex justify-between mr-4  ">
              <p className="text-[26px] opacity-60 font-sans font-bold">
                Employees to be Retired <br />{" "}
                <span className="text-sm">(Recently)</span>
              </p>
              <p className="bg-[#183D58] text-white font-bold w-12 rounded-xl h-12 text-center mt-4 p-2">
                10
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg">
            <div className="ml-10 flex justify-between mr-4">
              <p className="text-[26px] font-bold font-sans opacity-60">
                Amount of Loan Instalments <br />{" "}
                <span className="text-sm">(To be paid)</span>
              </p>
              <p className="bg-[#183D58] text-white font-bold w-12 rounded-xl h-12 text-center mt-4 p-2">
                06
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
