import React from "react";
import { Link } from "react-router-dom";

const approval2 = () => {
  return (
    <div className="font-sans bg-background h-screen overflow-y-auto">
      <div>
        <p className="mt-10 ml-6 text-headerColor text-[28px] font-semibold">
          Approve two Infomation
        </p>
      </div>

      <div>
        <div className="grid grid-cols-2 grid-flow-col gap-[100px] ml-[200px] mr-[200px] mt-[150px] ">
          <div className="bg-white rounded-[30px]">
            <div>
              <div className="text-headerColor text-[22px] font-semibold ml-[5%] mt-[5%] mb-[5%]">
                <p>Leave</p>
                <p>Approvals</p>
              </div>
            </div>
            <Link to="/approval2-info">
              <div className="flex justify-end mr-[5%] mb-[5%]">
                <button className="bg-[#183D58] text-white p-2 rounded-[20px] font-semibold">
                  View
                </button>
              </div>
            </Link>
          </div>
          <div className="bg-white rounded-[30px]">
            <div className="text-headerColor font-semibold text-[22px] ml-[5%] mt-[5%] mb-[5%]">
              <p>Employee Data Update</p>
              <p>Approvals</p>
            </div>

            <Link to="/approval2-user">
              <div className="flex justify-end">
                <button className="bg-[#183D58] rounded-[30px] text-white p-2 mr-[5%] font-semibold">
                  View
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default approval2;
