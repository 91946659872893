import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

const LatenessPermission = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // You can adjust the number of items per page here
  const [searchQuery, setSearchQuery] = useState(""); // State variable to store the search query

  // Dummy data for demonstration
  const data = [
    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    {
      empNo: "001",
      fullName: "John Doe",
      requestedDate: "2024-04-18",
    },
    {
      empNo: "002",
      fullName: "John shane",
      requestedDate: "2024-04-18",
    },

    // Add more dummy data as needed
  ];

  // Filter data based on search query
  const filteredData = data.filter((item) =>
    item.empNo.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when search query changes
  };

  return (
    <div className="bg-background h-full overflow-y-auto">
      <div>
        <p className="text-headerColor text-[25px] font-sans ml-6 mt-6 font-bold mb-8">
          Lateness Permission
        </p>
      </div>

      <div className="flex justify-between items-center mt-[-50px]">
        {/* Search section */}
        <div>
          <div>
            <div className="flex flex-col">
              <div className="flex gap-3 ml-5 items-center ">
                <div>
                  <label
                    htmlFor="nic_number"
                    className="text-sm mb-2  font-bold text-[#797C80] font-sans"
                  >
                    Search by EMP No
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter EMP No"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-[50px]">
          <div>
            <div className="bg-white p-5 rounded-xl relative bg-">
              <p className="font-bold text-[#797C80] text-[32px] ">
                View Document <br />
                Log
              </p>

              <div className="flex justify-between items-center mt-2">
                <Link to="/leave-doc">
                  <div>
                    <button
                      className="bg-[#183D58] p-2 rounded-[50px] font-sans font-semibold text-white mt-2"
                      //   onClick={() => setIsOpen(true)}
                    >
                      Click me
                    </button>
                  </div>
                </Link>
                <div>
                  <div className="bg-[#183D58] p-2 rounded-[22px] h-[37px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Permission Sheet */}
      <div>
        <div className="flex flex-col font-sans font-bold ml-6 mt-3 rounded-xl mr-[5%] ">
          <div>
            <p className="text-[16px] text-[black] mb-3">Permission Sheet</p>
            <hr className="w-[5%] border-b-2 border-primary mb-5 " />
          </div>
          <table className="table-auto border-none ">
            <thead>
              <tr className="bg-white text-[20px] ">
                <th className="text-primary px-4 py-2  ">Emp NO</th>
                <th className="text-primary px-4 py-2">Full Name</th>
                <th className="text-primary px-4 py-2">Requested Date</th>
                <th className="text-primary px-4 py-2">Upload</th>
                <th className="text-primary px-4 py-2">View Document</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td className="bg-white px-4 py-2">{item.empNo}</td>
                  <td className="bg-white px-4 py-2">{item.fullName}</td>
                  <td className="bg-white px-4 py-2">
                    <input
                      type="date"
                      required=""
                      placeholder="Search..."
                      className="input rounded-full h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                    />
                  </td>
                  <td className="bg-white px-4 py-2">
                    <input
                      className="h-10 rounded-[32px] border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#183D58] file:text-white file:text-sm file:font-medium file:h-10"
                      type="file"
                      id="picture"
                      accept="application/pdf"
                    />
                    <div className="text-red-600 text-sm mt-2">
                      Please upload only PDF files.
                    </div>
                  </td>
                  <td className="bg-white px-4 py-2">Data 6</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center mt-4 mb-5">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md "
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default LatenessPermission;
