import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Return from "../../../../Assets/icons/back-arrow.png";

function InAndOutTimeRegular() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(""); // State to hold the search input
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://back-demo-british.talentfort.live/v1/hris/attendence/gettransactionsreporthandler"
        );
        const result = await response.json();
        setData(result);
        setFilteredData(result); // Also initialize filteredData with fetched data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const keyword = e.target.value.toLowerCase();
    setSearch(keyword);
    const filtered = data.filter((item) => {
      const itemDate = item.employment_date
        ? new Date(item.employment_date)
        : null;
      const isStartDateMatch = startDate
        ? new Date(startDate) <= itemDate
        : true;
      const isEndDateMatch = endDate ? new Date(endDate) >= itemDate : true;

      return (
        (item.emp_code && item.emp_code.toLowerCase().includes(keyword)) ||
        (item.first_name && item.first_name.toLowerCase().includes(keyword)) ||
        (item.last_name && item.last_name.toLowerCase().includes(keyword)) ||
        (item.department &&
          item.department.toLowerCase().includes(keyword) &&
          isStartDateMatch &&
          isEndDateMatch)
      );
    });
    setFilteredData(filtered);
  };

  const handleResetFilter = () => {
    setSearch("");
    setStartDate("");
    setEndDate("");
    setFilteredData(data);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleTrackNow = () => {
    navigate("/in-and-out-shift");
  };

  const handleBack = () => {
    navigate("/attendance");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Time & Attendance Management
      </p>

      <div className="mt-5 ml-6 mb-[50px]">
        <img
          onClick={handleBack}
          src={Return}
          alt="return-icon"
          className="h-10 w-10 cursor-pointer"
        />
      </div>

      {/* Search */}
      <div className="flex justify-between items-center mt-[-50px]">
        {/* input fields */}
        <div>
          <div className="ml-6 flex gap-[40px]">
            <div>
              <p className="text-headerColor font-bold mb-2">Start Date</p>
              <input
                type="date"
                className="w-[217px] h-[55px] rounded-[22px]"
              ></input>
            </div>
            <div>
              <p className="text-headerColor font-bold mb-2">End Date</p>
              <input
                type="date"
                className="w-[217px] h-[55px] rounded-[22px]"
              ></input>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                value={search}
                onChange={handleFilterChange}
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Employee Code"
              />
            </div>
            <div>
              <p
                className="text-headerColor font-bold"
                onClick={handleResetFilter}
              >
                Reset
              </p>
            </div>
          </div>

          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <select
                className="w-[480px] h-[55px] rounded-[22px] bg-white"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Sort by Department
                </option>
                <option value="option1"></option>
              </select>
            </div>
          </div>
        </div>
        <div className="ml-[35%] mr-[5%]">
          <div className="mt-[10%]">
            <div
              key=""
              className="bg-white rounded-[32px] w-[350px] h-[200px] p-4 shadow-md flex flex-col justify-between"
            >
              <div>
                <h2 className="text-[25px] font-semibold mb-1 font-sans">
                  Shift Basis
                </h2>
                <h2 className="text-[25px] font-semibold mb-1 font-sans">
                  Employees
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button
                  className="bg-[#183D58] w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleTrackNow}
                >
                  Track Now
                </button>
                <div className="bg-[#183D58] w-[13px] h-[37px] rounded-xl"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <label className="font-semibold ml-[5%]">Attendance Sheet</label>
      <div className="ml-[6%]">
        <hr className="line border-t border-gray-300 w-[5%]"></hr>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%]">
          <thead>
            <tr className="bg-white text-[18px]">
              <th className="text-primary px-4 py-2 w-[20%]">Emp NO</th>
              <th className="text-primary px-4 py-2 w-[20%]">First Name</th>
              <th className="text-primary px-4 py-2 w-[20%]">Last Name</th>
              <th className="text-primary px-4 py-2 w-[20%]">Department</th>
              <th className="text-primary px-4 py-2 w-[20%]">Check In</th>
              <th className="text-primary px-4 py-2 w-[20%]">Check Out</th>
              <th className="text-primary px-4 py-2 w-[20%]">Day</th>
              <th className="text-primary px-4 py-2 w-[20%]">Exact Date</th>
              <th className="text-primary px-4 py-2 w-[20%]">Terminal Alias</th>
              <th className="text-primary px-4 py-2 w-[20%]">Upload Time</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id} className="bg-white text-[18px]">
                <td className="px-4 py-2">{item.emp_code}</td>
                <td className="px-4 py-2">{item.first_name}</td>
                <td className="px-4 py-2">{item.last_name}</td>
                <td className="px-4 py-2">{item.dept_name}</td>
                <td className="px-4 py-2">{item.check_in}</td>
                <td className="px-4 py-2">{item.check_out}</td>
                <td className="px-4 py-2">{item.weekday}</td>
                <td className="px-4 py-2">{item.att_date}</td>
                <td className="px-4 py-2">{item.terminal_alias}</td>
                <td className="px-4 py-2">{item.upload_time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {Array.from({
          length: Math.ceil(data.length / itemsPerPage),
        }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`${
              currentPage === index + 1 ? "bg-[#183D58] text-white" : "bg-white"
            } px-4 py-2 mx-1 rounded-md`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
}

export default InAndOutTimeRegular;
