import React, { useState, useEffect } from "react";

function ViewMore({ id, onClose }) {
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://back-demo-british.talentfort.live/v1/hris/employees/vieweditdata?id=${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEmployeeData(data[0]); // Assuming that the API always returns an array
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      // Cleanup logic here if needed
    };
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!employeeData) return <p>No employee data found.</p>;

  return (
    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full ">
      <div className="bg-white p-5 w-[800px] rounded-xl h-screen relative overflow-auto">
        <div className="mb-6">
          <p className="font-sans text-[32px] font-bold text-center">
            Updated Personal Details 
          </p>
          <div className="w-[100%] bg-[#ffffff] rounded-xl p-5 mb-[100px]">
            <p className="font-sans text-[20px] font-bold ">Personal Details</p>
            <hr className="line border-t border-gray-300 w-[25%]"></hr>{" "}
            <div className="grid grid-cols-3 gap-6">
              <div className="mt-2">
                <p className="text-[17px] font-[500] font-sans">Full Name</p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_fullname}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">Email</p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_email}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">Phone</p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_contact_no}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Permanent Address
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_permanent_address}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-[17px] font-[500] font-sans">
                  Name with Initials
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_name_initial}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  NIC Number
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_nic}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Date of Birth
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_dob}
                </p>
                <p className="text-[17px] font-[500] font-sans mt-2">
                  Temporary Address
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_temporary_address}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-[17px] font-[500] font-sans">Gender</p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_gender}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Marital Status
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_marital_status}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Gramasewa Division
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_gramasewa_division}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Police Division
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_police_division}
                </p>
              </div>{" "}
            </div>
            <p className="font-sans text-[20px] mt-5 font-bold ">
              Employyement Details
            </p>
            <hr className="line border-t border-gray-300 w-[25%]"></hr>{" "}
            <div className="grid grid-cols-3 gap-6">
              <div className="mt-2">
                <p className="text-[17px] font-[500] font-sans">Designation</p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_designation}
                </p>
                <p className="text-[17px] font-[500] font-sans mt-2">
                  Department
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_department}
                </p>
                <p className="text-[17px] font-[500] font-sans mt-2">
                  Date of Appointment
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.date_of_appointment}
                </p>
                <p className="text-[17px] font-[500] font-sans mt-2">
                  Probation Status
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.probation_complete}
                </p>
                <div className="mt-2">
                  <p className="text-[17px] font-[500] font-sans">
                    Cost of Allowance
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.cost_of_allowance}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    SLADC Allowance
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.sladc_allowance}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    AJT Allowance
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.ajt_allowance}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    Active Status
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.employee_active_status}
                  </p>
                </div>
              </div>
              <div>
                <div className="mt-2">
                  <p className="text-[17px] font-[500] font-sans">
                    Permanent Appointment Date
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.permanant_appoinment_date}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    First EB Completion Date
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.first_eb_completion_date}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    Second EB Completion Date
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.second_eb_completion_date}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    Third EB Completion Date
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.third_eb_completion_date}
                  </p>
                  <p className="text-[17px] font-[500] font-sans mt-2">
                    Designation Grade
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.designation_grade}
                  </p>
                </div>
                <div className="mt-2">
                  <p className="text-[17px] font-[500] font-sans">EPF Number</p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.employee_epf_no}
                  </p>

                  <p className="text-[17px] font-[500] font-sans mt-2">
                    Basic Salary
                  </p>
                  <p className="text-[14px] font-[700] mt-1 font-sans">
                    {employeeData.employee_basic_salary}
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <p className="text-[17px] font-[500] font-sans">
                  Account Number
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_account_no}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Bank Name
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_bank_name}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Branch Name
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_branch_name}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Bank Code
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_bank_code}
                </p>

                <p className="text-[17px] font-[500] font-sans mt-2">
                  Branch Code
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.employee_branch_code}
                </p>
                <p className="text-[17px] font-[500] font-sans mt-2">
                  Salary Code
                </p>
                <p className="text-[14px] font-[700] mt-1 font-sans">
                  {employeeData.salary_code}
                </p>
              </div>

              <div className="mt-2 col-span-3">
                <p className="font-sans text-[20px] mt-5 font-bold">
                  Dependents Details
                </p>
                <hr className="line border-t border-gray-300 w-[25%]"></hr>
                {employeeData.employee_dependent_details.map(
                  (dependent, index) => (
                    <div
                      key={dependent.employee_dependent_details_id}
                      className="mb-4"
                    >
                      <p className="text-[17px] font-[500] font-sans">
                        Dependent {index + 1}
                      </p>
                      <div className="grid grid-cols-4 gap-4">
                        {" "}
                        {/* Setup a grid with 4 columns */}
                        <p className="text-[14px] font-[700] mt-1 font-sans">
                          Name: {dependent.employee_dependent_name}
                        </p>
                        <p className="text-[14px] font-[700] mt-1 font-sans">
                          Relationship:{" "}
                          {dependent.employee_dependent_relationship}
                        </p>
                        <p className="text-[14px] font-[700] mt-1 font-sans">
                          NIC: {dependent.employee_dependent_nic}
                        </p>
                        <p className="text-[14px] font-[700] mt-1 font-sans">
                          DOB: {dependent.employee_dependent_dob}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="mt-2 col-span-3">
                <p className="text-[17px] font-[500] font-sans">
                  Academic Qualifications
                </p>
                {employeeData.employee_acadamic_qualification_details.map(
                  (qualification) => (
                    <div
                      key={qualification.employee_acadamic_qualification_id}
                      className="mb-2"
                    >
                      <p className="text-[14px] font-[700] mt-1 font-sans">
                        Qualification:{" "}
                        {qualification.employee_acadamic_qualification}
                      </p>
                    </div>
                  )
                )}
              </div>
              <div className="mt-2 col-span-3">
                <p className="text-[17px] font-[500] font-sans">
                  Training Details
                </p>
                {employeeData.employee_training_details.map((training) => (
                  <div
                    key={training.employee_training_details_id}
                    className="mb-2"
                  >
                    <p className="text-[14px] font-[700] mt-1 font-sans">
                      Training Type: {training.type_of_training}
                    </p>
                  </div>
                ))}
              </div>
              <div className="mt-2 col-span-3">
                <p className="text-[17px] font-[500] font-sans">
                  Disciplinary Actions
                </p>
                {employeeData.employee_disciplinary_details.map(
                  (disciplinary) => (
                    <div
                      key={disciplinary.employee_disciplinary_details_id}
                      className="mb-2"
                    >
                      <p className="text-[14px] font-[700] mt-1 font-sans">
                        Call Date:{" "}
                        {disciplinary.employee_disciplinary_call_date}
                      </p>
                      <p className="text-[14px] font-[700] mt-1 font-sans">
                        Original File Name:{" "}
                        {disciplinary.employee_disciplinary_original_file_name}
                      </p>
                      <a
                        href={disciplinary.file_path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[14px] font-[700] mt-1 font-sans text-blue-600 underline"
                      >
                        Download File
                      </a>
                    </div>
                  )
                )}
              </div>
              <div className="mt-2 col-span-3">
                <p className="text-[17px] font-[500] font-sans">
                  Uploaded Files
                </p>
                {employeeData.employee_upload_files.map((file) => (
                  <div key={file.employee_upload_files_id} className="mb-2">
                    <p className="text-[14px] font-[700] mt-1 font-sans">
                      Original File Name: {file.original_file_name}
                    </p>
                    <a
                      href={file.employee_upload_path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[14px] font-[700] mt-1 font-sans text-blue-600 underline"
                    >
                      Download File
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewMore;
