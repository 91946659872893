import React, { useState } from "react";
import PDF_Icon from "../../../../Assets/icons/pdf.png";

const DocumentLog = () => {
  const [searchQuery, setSearchQuery] = useState(""); // State variable for search query

  // Dummy data for demonstration
  const data = [
    {
      empNo: "001",
      fullName: "John Doe",
      submittedDate: "2024-04-18",
      requestedDate: "2024-04-20",
    },

    {
      empNo: "002",
      fullName: "John shane",
      submittedDate: "2024-04-18",
      requestedDate: "2024-04-20",
    },
    // Add more dummy data as needed
  ];

  // Event handler for handling changes in the search input
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered data based on search query
  const filteredData = data.filter((item) =>
    item.empNo.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="text-[32px] text-[#797C80] font-bold mt-6 ml-6 font-sans ">
          Leave Log
        </p>
      </div>

      <div>
        <div>
          <div className="flex flex-col">
            <label
              htmlFor="nic_number"
              className="text-xl mb-2  font-bold text-[#797C80] font-sans"
            ></label>
            <select
              id={``}
              name={``}
              autoComplete=""
              className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
            >
              <option className="font-sans">Department</option>
              <option>Department</option>
              <option>Active</option>
              <option>Inactive</option>
            </select>
          </div>
        </div>

        {/* Joined by year */}
        <div className="relative font-sans group hidden sm:block">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px]  h-10 duration-300 rounded-full border border-white px-2 py-1"
          />
        </div>
      </div>

      <div>
        <div className="mt-6 ml-6">
          <p className="text-[16px] text-[black] mb-3 font-bold font-sans ">
            Log Sheet
          </p>
          <hr className="w-[5%] border-b-2 border-primary mb-5 " />
        </div>
        <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl mr-[5%] ">
          <table className="table-auto border-none ">
            <thead>
              <tr className="bg-white ">
                <th className="text-primary px-4 py-2 ">Emp NO</th>
                <th className="text-primary px-4 py-2">Full Name</th>
                <th className="text-primary px-4 py-2">Submitted Date</th>
                <th className="text-primary px-4 py-2">Requested Date</th>
                <th className="text-primary px-4 py-2">View Document</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td className="bg-white px-4 py-2">{item.empNo}</td>
                  <td className="bg-white px-4 py-2">{item.fullName}</td>
                  <td className="bg-white px-4 py-2">{item.submittedDate}</td>
                  <td className="bg-white px-4 py-2">{item.requestedDate}</td>
                  <td className="bg-white px-4 py-2">
                    <div className=" items-center justify-center">
                      <div className="flex justify-center items-center gap-4">
                        <div>
                          <p className="bg-background rounded-[6px] p-3 ">
                            File Name
                          </p>
                        </div>
                        <div>
                          <img src={PDF_Icon} alt="pdf-icon" />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DocumentLog;
