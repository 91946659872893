import React, { useState,useEffect } from "react";

function AddNewTraining({ employee_no, setFetchData, onClose }) {
  const [TrainingSections, setTrainingSections] = useState(1);
  const [traningSectionsCounts, setTraningSectionsCounts] = useState([]);
  const [trainingtype, settrainingtype] = useState([]);

  const handleTraningSection = () => {
    setTrainingSections((prev) => prev + 1);
    setTraningSectionsCounts((prev) => [...prev, prev.length + 1]);
  };
  const createTrainingDevelopmentRequestBody = () => {
    const trainingDevelopment = [];
    const maxSections = Math.min(TrainingSections, 10);
  
    for (let i = 0; i < maxSections; i++) {
      const selectedTraining = document.getElementById(`type_of_training_${i}`).value;
      const trainingData = {
        type_of_training: selectedTraining,
      };
      trainingDevelopment.push(trainingData);
    }
  
    return trainingDevelopment;
  };

  useEffect(() => {
    // Fetch designations when the component mounts
    fetch(
      "https://back-demo-british.talentfort.live/v1/hris/performance/training/gettraining"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the fetched designations to state
        settrainingtype(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);


  const renderTraningSections = () => {
    const sections = [];
    const maxSections = Math.min(TrainingSections, 10);
    for (let i = 0; i < maxSections; i++) {
      sections.push(
        <div key={i} className="">
          <div className="flex items-center justify-center">
            <div className="w-[100%] bg-[#ffffff] rounded-xl p-8 ">
              <div>
                <form>
                  <div className="flex  flex-col md:flex-row font-bold">
                    <div className="flex flex-col mr-10">
                      <label
                        htmlFor={`type_of_training${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Type of Training
                      </label>
                      <select
                        id={`type_of_training_${i}`}
                        name={`type_of_training_${i}`}
                        className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#183D58] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                      >
                        <option value="">Select</option>
                        {trainingtype.map((trainingtype) => (
                          <option
                            key={trainingtype.training_name}
                            value={trainingtype.training_name}
                          >
                            {trainingtype.training_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };


  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      // Log the data being sent
      console.log("Data to be sent:", {
        employee_no: employee_no,
        employee_training_details: createTrainingDevelopmentRequestBody(),
      });

      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/employees/insertEmployeeTrainingDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            employee_no: employee_no,
            employee_training_details: createTrainingDevelopmentRequestBody(),
          }),
        }
      );
      const data = await response.json();
      console.log(data); // Handle the response data as needed
      setFetchData(prevKey => prevKey + 1);

      onClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="bg-[#ffffff] w-[100%] rounded-[35px] h-[700px] mt-[5%] text-[32px] ml-[5%] mr-[5%] font-bold overflow-y-auto">
      <p className="text-4xl mb-4 ml-7 mt-[8%] mr-10 font-bold text-[#797C80] ">
        Add Training Section
      </p>
      <div>{renderTraningSections()}</div>
      <div className="ml-[3%]">
        <button
          className="rounded-full bg-[#5B6D49] mb-5 ml-5 px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          onClick={handleTraningSection}
        >
          Add Training Section
        </button>
      </div>
      <div className="flex gap-5 justify-end mr-5 mb-10">
        <button
          onClick={onClose}
          className="rounded-full bg-[rgb(171,175,166)] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="rounded-full bg-[#5B6D49] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddNewTraining;
