import React from "react";

const loan_eligibility = () => {
  return (
    <div className="bg-background overflow-y-auto h-screen font-sans">
      <div>
        <p className="text-headerColor text-[30px] font-semibold mt-6 ml-6">
          Check Eligibility
        </p>
      </div>

      <div className="mt-[10%]">
        <div className="flex justify-center items-center">
          <div className="mr-6">
            <lable className="text-[20px] font-semibold text-headerColor">
              Search by Employee ID :{" "}
            </lable>
          </div>
          <div>
            <input
              placeholder="Enter User ID"
              className="rounded-[20px] w-[500px] h-[50px] font-semibold pl-4 flex items-center"
            />
          </div>

          <div className="ml-5">
            <button className="bg-[#183D58] text-white p-3 rounded-[20px] font-semibold">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default loan_eligibility;
