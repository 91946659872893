import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const TrainingCards = () => {
  const navigate = useNavigate();
  const [trainingData, setTrainingData] = useState([]);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  const handleUpcomingTrainingsClick = () => {
    navigate("/upcoming-training");
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/performance/training/gettraining"
      );
      const data = await response.json();
      setTrainingData(data);
      const upcoming = data.filter(
        (training) => training.training_status === "UPCOMING"
      ).length;
      const completed = data.filter(
        (training) => training.training_status === "COMPLETED"
      ).length;
      setUpcomingCount(upcoming);
      setCompletedCount(completed);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div className="font-sans mt-6 mb-3 ml-6 text-[32px] font-bold text-[#797C80]">
        <p>Trainings</p>
      </div>

      <div>
        <div>
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="nic_number"
                className="text-xl mb-2 font-bold text-[#797C80] font-sans"
              ></label>
              <select
                id={``}
                name={``}
                autoComplete=""
                className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              >
                <option className="font-sans-bold ">Department</option>
                <option>Dep 01</option>
                <option>Dep 02</option>
                <option>Dep 03</option>
              </select>
            </div>
          </div>

          <div className="mt-3">
            <div>
              <div className="flex flex-col">
                <label
                  htmlFor="nic_number"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                ></label>
                <select
                  id={``}
                  name={``}
                  autoComplete=""
                  className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                >
                  <option className="font-sans-bold ">All</option>
                  <option>Training #</option>
                  <option>Training #</option>
                  <option>Training #</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form class="form relative">
              <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  class="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
            </form>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-10 ml-6 mr-[2%]">
        <div>
          <div className="bg-white rounded-[32px] p-5 font-sans font-semibold text-[32px] text-[#797C80]">
            <div>
              <p>Completed</p>
              <p>Trainings</p>
            </div>

            <div className="flex items-center justify-between">
              <div className="mt-2">
                <Link to="/completed-training">
                  <button className="text-[15px] font-bold text-white bg-[#183D58] p-2 rounded-[32px]">
                    Click Here
                  </button>
                </Link>
              </div>
              <div className="bg-[#183D58] p-1 h-[37px] rounded-[22px]"></div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-white rounded-[32px] p-5 font-sans font-semibold text-[32px] text-[#797C80]">
            <div>
              <p>Upcoming</p>
              <p>Trainings</p>
            </div>

            <div className="flex items-center justify-between">
              <div className="mt-2">
                <button
                  onClick={handleUpcomingTrainingsClick}
                  className="text-[15px]  text-white bg-[#183D58] p-2 rounded-[32px] font-bold"
                >
                  Click Here
                </button>
              </div>
              <div className="bg-[#183D58] p-1 h-[37px] rounded-[22px]"></div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[32px] h-[300px]">
          <div className=" p-5 font-sans font-semibold text-[32px] text-[#797C80]">
            <div>
              <p>Count</p>
            </div>
          </div>

          <div>
            <p className="ml-6 font-semibold text-[20px] text-[#797C80]">
              Total Completed Trainings
            </p>

            <button className="bg-[#183D58] font-semibold text-white mt-3 w-[90px] text-[20px] rounded-[32px] ml-6 p-2">
              {completedCount}
            </button>
          </div>

          <div className="ml-6 font-sans font-semibold text-[#797C80] mt-4">
            <p>Total Upcoming Trainings</p>
          </div>
          <div>
            <button className="bg-[#183D58] font-semibold text-white mt-3 w-[90px] text-[20px] rounded-[32px] ml-6 p-2">
              {upcomingCount}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCards;
