import React, { useState, useEffect } from "react";
import Retirement from "./retirement";
import { Link } from "react-router-dom";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import CSV from "../../../../Assets/icons/csv.png";
import approvalpending from "../../../../Assets/icons/image.png";
const View_Export_Table = () => {
  const [employees, setEmployees] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  useEffect(() => {
    fetchEmployeeData();
  }, []);
  const fetchEmployeeData = async () => {
    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/employees/getemployeebasicdetails"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEmployees(data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };
  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };
  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = employees.filter((employee) => {
    // Check if employee matches the selected status filter
    const statusFilterMatch =
      filterOption === "" ||
      (filterOption === "ACTIVE" &&
        employee.employee_active_status === "ACTIVE") ||
      (filterOption === "INACTIVE" &&
        employee.employee_active_status === "INACTIVE");
    // Check if employee matches the search query
    const searchMatch =
      !searchQuery ||
      employee.employee_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.employee_epf_no
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      employee.employee_nic.toLowerCase().includes(searchQuery.toLowerCase());
    // Check if employee matches the selected month
    const monthMatch =
      !searchMonth ||
      new Date(employee.permanant_appoinment_date).getMonth() + 1 ===
        parseInt(searchMonth);
    // Check if employee matches the selected year
    const yearMatch =
      !searchYear ||
      new Date(employee.permanant_appoinment_date).getFullYear().toString() ===
        searchYear;
    // Return true if all filters match
    return statusFilterMatch && searchMatch && monthMatch && yearMatch;
  });
  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleExportCSV = () => {
    // Convert table data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      employees.map((row) => Object.values(row).join(",")).join("\n");
    // Create a hidden link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "employee_data.csv");
    document.body.appendChild(link);
    // Trigger the download
    link.click();
    // Display alert after a short delay
    setTimeout(() => {
      window.alert("Successfully Downloaded");
    }, 100);
  };
  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View & Export Details
      </p>
      <div className="flex justify-between overflow-hidden">
        <div>
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="nic_number"
                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
              ></label>
              <select
                id="filter"
                name="filter"
                value={filterOption}
                onChange={handleFilterChange}
                className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              >
                <option value="">All</option>
                <option value={"ACTIVE"}>Active</option>
                <option value={"INACTIVE"}>Inactive</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by EMP NO, EPF NO, NIC"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="monthFilter"
              name="monthFilter"
              value={searchMonth}
              onChange={handleMonthChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px]  h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option
                className=" placeholder-white sm:text-sm px-2 font-sans"
                value=""
              >
                Filter By Joined Month
              </option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="yearFilter"
              name="yearFilter"
              onChange={handleYearChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px]  h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option value="">Filter By Joined Year</option>
              {Array.from(
                { length: 20 },
                (v, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="p-[50px] ">
          <Retirement />
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
        <table className="table-auto border-none overflow-x-auto">
          <thead>
            <tr className="bg-white font-sans text-[16px] ">
              <th className="text-primary px-4 py-2 ">Emp NO</th>
              <th className="text-primary px-4 py-2">Epf NO</th>
              <th className="text-primary px-4 py-2">Full Name</th>
              <th className="text-primary px-4 py-2">Gender</th>
              <th className="text-primary px-4 py-2">Current Address</th>
              <th className="text-primary px-4 py-2">NIC</th>
              <th className="text-primary px-4 py-2">Joined Date</th>
              <th className="text-primary px-4 py-2">Date of Birth</th>
              <th className="text-primary px-4 py-2">
                Status (Active/Inactive)
              </th>
              <th className="text-primary px-4 py-2">Document</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems.map((employee) => (
              <tr key={employee.id}>
                <td className="bg-white px-4 py-2">{employee.employee_no}</td>
                <td className="bg-white px-4 py-2">
                  {employee.employee_epf_no}
                </td>
                <td className="bg-white px-4 py-2">
                  {employee.employee_name_initial}
                </td>
                <td className="bg-white px-4 py-2">
                  {employee.employee_gender}
                </td>
                <td className="bg-white px-4 py-2">
                  {employee.employee_permanent_address}
                </td>
                <td className="bg-white px-4 py-2">{employee.employee_nic}</td>
                <td className="bg-white px-4 py-2">
                  {employee.permanant_appoinment_date}
                </td>
                <td className="bg-white px-4 py-2">{employee.employee_dob}</td>
                <td className="bg-white px-4 py-2">
                  {employee.employee_active_status}
                </td>
                <td className="bg-white px-4 py-2">
                  {employee.approved_status === null ||
                  employee.approved_status === "PENDING" ? (
                    <div className="flex items-center justify-center">
                      <div className="bg-[#EEE0DF] rounded-[22px] w-[220px] h-[44.41px] flex items-center gap-2 justify-center ">
                        <img
                          src={approvalpending}
                          alt="approvalpending"
                          className="w-8 h-8 "
                        />{" "}
                        <span>Approval Pending</span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center gap-3 w-[220px]">
                      <button className="bg-[#183D58] p-2 rounded-md text-white w-[100px]">
                        Download
                      </button>
                      <Link to={`/user-profile/${employee.employee_no}`}>
                        <button className="bg-gray-300 p-2 rounded-md w-[100px]">
                          Edit
                        </button>
                      </Link>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-[#183D58] text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>

      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-[#183D58] text-white font-sans p-3 rounded-[20px]">
          <div className="flex items-center" onClick={handleExportCSV}>
            <div onClick={handleExportCSV} className="font-semibold">
              Export CSV
            </div>
            <div>
              <img className="h-8 w-8" src={CSV} alt="logo-csv" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
export default View_Export_Table;
