import React, { useState } from "react";
import UploadIcon from "../../../../Assets/icons/Upload.png";
import PDF from "../../../../Assets/icons/png.png"; // Assuming you have a PDF icon image

const EditLeave = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [empNo, setEmpNo] = useState("");
  const [fullName, setFullName] = useState("");
  const [requestedDate, setRequestedDate] = useState("");

  const [errorMessage, setErrorMessage] = useState({
    empNo: "",
    fullName: "",
    requestedDate: "",
  });

  const [showPopup, setShowPopup] = useState(true); // State to control the visibility of the popup

  const handleClose = () => {
    setShowPopup(false); // Set the state to false to close the popup
  };

  // Function to handle file selection
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    let isError = false;
    const errors = {
      empNo: "",
      fullName: "",
      requestedDate: "",
    };

    if (!empNo) {
      errors.empNo = "Please enter Employee Number";
      isError = true;
    }

    if (!fullName) {
      errors.fullName = "Please enter Full Name";
      isError = true;
    }

    if (!requestedDate) {
      errors.requestedDate = "Please select Requested Date";
      isError = true;
    }

    if (isError) {
      setErrorMessage(errors);
      return;
    }

    setErrorMessage({ empNo: "", fullName: "", requestedDate: "" });

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await fetch("backend/uploadEndpoint", {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          // File uploaded successfully
          setSubmissionStatus("success");
          console.log("File uploaded successfully!");
        } else {
          // Handle error
          setSubmissionStatus("error");
          console.error("Failed to upload file.");
        }
      } catch (error) {
        setSubmissionStatus("error");
        console.error("Error uploading file:", error);
      }
    } else {
      console.log("No file selected.");
    }
  };

  return (
    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <div className="bg-[#F3F8FF] p-2 rounded-[80px]">
        <div className="mb-6">
          <p className="font-sans text-[32px] font-bold text-center">
            Update Leave Details
          </p>

          <div className="mt-5 flex flex-col">
            <div className="flex flex-row items-center">
              <div>
                <p className="font-sans font-semibold">Leave Type :</p>
              </div>

              <div className="grid grid-cols-3 justify-between">
                <div>
                  <div>
                    <label
                      htmlFor=""
                      className="text-xl mb-2  font-bold text-[#797C80] font-sans"
                    ></label>
                    <select
                      id={``}
                      name={``}
                      autoComplete=""
                      className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    >
                      <option className="font-sans-bold ">Type</option>
                      <option>Short Leave</option>
                      <option>Half Day</option>
                    </select>
                  </div>
                  {errorMessage.empNo && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessage.empNo}
                    </p>
                  )}
                </div>

                <div className="flex flex-row items-center">
                  <div className="ml-[59%]">
                    <p className="font-sans font-semibold ">EMP NO</p>
                  </div>
                  <div className="ml-[28%]">
                    <input
                      className={`w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white  px-2 font-sans ${
                        errorMessage.fullName ? "border-red-500" : ""
                      }`}
                      placeholder="Enter Employee Number"
                      value={empNo}
                      onChange={(e) => setEmpNo(e.target.value)}
                    ></input>
                    {errorMessage.empNo && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.empNo}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">Full Name :</p>
                  </div>
                  <div>
                    <input
                      className={`w-[300px] ml-8 h-10 rounded-[73px] py-1.5 bg-white  px-2 font-sans ${
                        errorMessage.fullName ? "border-red-500" : ""
                      }`}
                      placeholder="Enter Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    ></input>
                    {errorMessage.fullName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.fullName}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {" "}
                <div className="flex flex-row items-center mt-4">
                  <div className="ml-[12%]">
                    <p className="font-sans font-semibold">Requested Date :</p>
                  </div>
                  <div>
                    <input
                      type="date"
                      className={`w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white  px-2 font-sans ${
                        errorMessage.requestedDate ? "border-red-500" : ""
                      }`}
                      value={requestedDate}
                      onChange={(e) => setRequestedDate(e.target.value)}
                    ></input>
                    {errorMessage.requestedDate && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.requestedDate}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <div className="bg-white  p-5 rounded-[32px]">
              <div>
                <p className="text-center font-sans text-[27px] font-semibold opacity-60">
                  Upload Files
                </p>

                <p className="font-sans text-[20px] opacity-45 text-center">
                  Select and upload the files of your choice
                </p>
              </div>
              <hr className="border-t-4 border-gray-400" />

              <div className="mt-10 ml-[35%]">
                <div className="bg-[#E1E6DC] w-[50%] border border-dotted border-black rounded-[32px] p-5">
                  <div className="h-8 flex items-center ml-[45%]">
                    <img src={UploadIcon} alt="upload-icon" />
                  </div>

                  <div className="h-8">
                    <p className="text-[14px] font-sans font-semibold text-center mt-3">
                      Choose a PDF file or drag & drop it here
                    </p>
                  </div>
                  <div className="h-8">
                    <p className="font-sans text-sm opacity-30 text-center">
                      Up to 50MB
                    </p>
                  </div>
                  <div className="h-8 flex justify-center ">
                    <label className="text-center block text-black rounded-[30px] bg-white p-1 w-[100px] font-semibold cursor-pointer hover:text-blue-700">
                      Browse
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleFileSelect}
                      />
                    </label>
                  </div>
                  {/* Display selected file */}
                  {selectedFile && (
                    <div className="h-8 mt-3 flex items-center">
                      <img src={PDF} alt="pdf-icon" className="w-8 h-8 mr-2" />
                      <p className="font-sans text-sm">{selectedFile.name}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Display submission status message */}
        {submissionStatus === "success" && (
          <p className="text-green-500 text-center mt-3">
            Successfully Submitted
          </p>
        )}
        <div className="flex justify-center mt-5 space-x-3">
          <button
            className="bg-[#183D58] p-2 rounded-[22px] text-white"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button className="bg-gray-500 p-2 rounded-[22px] text-white">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditLeave;
