import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";

const Retirement = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the popup visibility
  const [retirementData, setRetirementData] = useState([]); // State to store retirement data

  useEffect(() => {
    const fetchRetirementData = async () => {
      try {
        const response = await fetch(
          "https://back-demo-british.talentfort.live/v1/hris/employees/retirementdata"
        );
        if (response.ok) {
          const data = await response.json();
          setRetirementData(data);
        } else {
          console.error("Failed to fetch retirement data");
        }
      } catch (error) {
        console.error("Error occurred while fetching retirement data:", error);
      }
    };

    fetchRetirementData();
  }, []);

  return (
    <div className="bg-white p-5 rounded-xl relative">
      <p className="font-bold text-[#797C80] text-[32px] ">
        Upcoming <br />
        Retirements
      </p>

      <div className="flex justify-between items-center mt-2">
        <div>
          <button
            className="bg-[#183D58] p-2 rounded-[50px] text-white mt-2 font-sans font-semibold "
            onClick={() => setIsOpen(true)}
          >
            Click me
          </button>
        </div>

        <div>
          <div className="bg-[#183D58] p-2 rounded-[22px] h-[37px]"></div>
        </div>
      </div>

      {/* Popup/Modal */}
      {isOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-[40%] h-[50%] relative">
            <button
              className="absolute top-2 right-2 bg-white text-[#797C80] rounded-full p-1"
              onClick={() => setIsOpen(false)}
            >
              <div className="bg-[#EFA5A5] rounded-full p-2">
                <IoCloseSharp />
              </div>
            </button>
            <p className="text-center font-sans font-bold text-[24px] text-[#797C80] mb-[8%] ">
              Upcoming Retirements
            </p>
            <div className="max-h-[250px] overflow-y-auto">
              <table className="w-full border-none scrollbar-green">
                <thead>
                  <tr>
                    <th className="bg-white text-[16px] font-normal font-sans">
                      Name & Position
                    </th>
                    <th className="bg-white text-[16px] font-normal font-sans">
                      Retirement Date
                    </th>
                    <th className="bg-white text-[16px] font-normal font-sans">
                      Onboarded On
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {retirementData.map((data, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-100" : ""}
                    >
                      <td className="py-2 px-4 font-bold">
                        {data.employee_department}
                      </td>
                      <td className="text-red-700  font-bold rounded-lg ">
                        <span className="bg-red-200 p-1 rounded-xl">
                          {data.employee_retirement_date}
                        </span>
                      </td>
                      <td className="py-2 px-4 font-bold">
                        {data.date_of_appoinment}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Retirement;
