import React from "react";
import { Link } from "react-router-dom";
const performance_nav = () => {
  return (
    <div className="bg-background h-screen overflow-y-auto overflow-x-hidden">
      <div>
        <p className="text-[32px] text-headerColor font-semibold ml-6 mt-6">
          {" "}
          Performance
        </p>
      </div>

      <div>
        <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
          <form className="form relative">
            <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
              <svg
                className="w-5 h-5 text-gray-700"
                aria-labelledby="search"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16"
                width="17"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="1.333"
                  stroke="currentColor"
                  d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              required=""
              placeholder="Search..."
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            />
          </form>
        </div>
      </div>

      <div className="mt-5 ml-6">
        <div className="grid grid-cols-3 grid-flow-col gap-4">
          <div className="bg-white p-5 rounded-[20px]">
            <div>
              <p className="text-headerColor font-semibold text-[32px]">
                Training
              </p>
            </div>

            <div className="flex justify-between items-center">
              <Link to="/training">
                {" "}
                <button className="bg-[#183D58] mt-5 text-white p-2 rounded-[30px] font-bold">
                  Click me
                </button>
              </Link>
              <div>
                <div className="bg-[#183D58] h-[37px] w-[13px] rounded-[22px]"></div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[20px] ">
            <div className="text-headerColor font-semibold text-[28px] ml-6 mt-5">
              <p>Salary </p>
              <p>Increments</p>
            </div>

            <div className="flex justify-between items-center">
              {" "}
              <Link to="/salary-increments">
                <div>
                  <button className="ml-6 mt-5 bg-[#183D58] text-white p-2 rounded-[30px] font-bold">
                    Click me
                  </button>
                </div>
              </Link>
              <div className="bg-[#183D58] h-[37px] w-[13px] rounded-[22px] mr-5"></div>
            </div>
          </div>
          <div className="bg-white rounded-[20px]">
            <div>
              <p className="text-headerColor font-semibold text-[32px] ml-6 mt-5">
                Examinations
              </p>
            </div>

            <div className="flex justify-between items-center ">
              {" "}
              <Link to="/examinations">
                {" "}
                <div className="ml-6 mt-5 mb-[50px]">
                  <button className="bg-[#183D58] p-2 rounded-[30px] text-white font-bold">
                    Click me
                  </button>
                </div>
              </Link>
              <div className="bg-[#183D58] h-[37px] w-[13px] rounded-[22px] mr-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default performance_nav;
